import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { DateInUserTimezonePipe } from './dateInUserTimeZone.pipe';
import { GetParticipantInfoPipe } from './getParticipantInfo.pipe';
import { RoleAccessPipe } from './roleAccess.pipe';

const pipes = [GetParticipantInfoPipe, DateInUserTimezonePipe, RoleAccessPipe];

@NgModule({
  declarations: [...pipes],
  imports: [CommonModule],
  exports: [...pipes],
  providers: [...pipes]
})
export class PipeModule {}
