import { Injectable } from '@angular/core';
import { NgbDate } from '@ng-bootstrap/ng-bootstrap';
import { Observable, Subject, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { Constants } from 'src/app/core/constants/constants';
import {
  UserDetails,
  userResponse
} from 'src/app/core/constants/dashboard-modal';
import { HttpWrapperService } from 'src/app/core/services/http-service/http-wrapper.service';
import { ConfigData } from '../../../dashboard/settings/settings-constants/types-config/types';

@Injectable({
  providedIn: 'root'
})
export class DashboardService {
  public configData = new Subject<ConfigData>();

  constructor(private httpClientWrapper: HttpWrapperService) {}

  getUserDetails(userId: number): Observable<UserDetails> {
    return this.httpClientWrapper
      .get(`${Constants.apiPaths.getUserInfo}/${userId}`)
      .pipe(
        map((res: userResponse) => {
          return res.data;
        })
      )
      .pipe(
        catchError((e) => {
          return throwError(e);
        })
      );
  }

  ngbDateToDate(date: NgbDate) {
    return new Date(`${date.year}, ${date.month},${date.day}`);
  }
}
