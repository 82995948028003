<app-in-app-notification></app-in-app-notification>
<header class="header">
  <div class="container-xl">
    <div class="header__div">
      <div class="d-flex justify-content-between align-items-center pointer" (click)="navigateToDashboard()">
        <!-- TODO: get the information from DB for the Logos -->
        <img src="{{constants.logoPath}}" *ngIf="client?.tenantName === clients.mpwr" alt="logo-image" aria-label="logo">
        <img src="{{constants.cellComLogo}}" class="w-50" *ngIf="client?.tenantName === clients.cellcom" alt="logo-image"
          aria-label="logo">
        <img src="{{constants.royFossLogo}}" class="w-50" *ngIf="client?.tenantName === clients.royfoss" alt="logo-image"
          aria-label="logo">
        <img src="{{constants.queenShopLogo}}" class="w-50" *ngIf="client?.tenantName === clients.queensshop" alt="logo-image"
          aria-label="logo">
        <img src="{{constants.optimyLogo}}" width="76" height="50" class="optimy-logo" *ngIf="checkClients()" alt="logo-image"
          aria-label="logo">
      </div>
      <div class="d-flex justify-content-center align-items-center">
        <div class="settings mr-3">
          <span class="pointer" (click)="settings()">
            <svg-icon name="settings" class="common-icon"></svg-icon>
          </span>
        </div>
        <app-notification></app-notification>
        <button class="sign-out" aria-label="sign out" [placement]="[ 'auto']" [ngbPopover]="profilePopover"></button>
        <ng-template #profilePopover>
          <div class="profile-popover">
            <ul>
              <li>
                <span [innerHTML]="'common.account' | translate"></span>
                <button class="log-out mt-1" (click)="signOut(false)" [innerHTML]="'common.logOut' | translate"></button>
              </li>
              <li>
                <span class="version mt-1">{{appVersion}}</span>
              </li>
            </ul>
          </div>
        </ng-template>
      </div>
    </div>
  </div>
</header>
