import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import { Clients, Constants } from './app/core/constants/constants';
import { CurrentClient } from './app/core/constants/common.enum';

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .then(() => {
    if ('serviceWorker' in navigator) {
      navigator.serviceWorker.register('ngsw-worker.js');
    }
  })
  .catch((err) => console.log(err));

//For Adding google analytics in index.html file
const client: CurrentClient = JSON.parse(
  localStorage.getItem(Constants?.client)
);
if (client?.tenantName === Clients.cellcom) {
  const html =
    '<!-- Facebook Pixel Code -->\n' +
    '<script>\n' +
    '!function(f,b,e,v,n,t,s)\n' +
    '{if(f.fbq)return;n=f.fbq=function(){n.callMethod?\n' +
    'n.callMethod.apply(n,arguments):n.queue.push(arguments)};\n' +
    "if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';\n" +
    'n.queue=[];t=b.createElement(e);t.async=!0;\n' +
    't.src=v;s=b.getElementsByTagName(e)[0];\n' +
    "s.parentNode.insertBefore(t,s)}(window,document,'script',\n" +
    "'https://connect.facebook.net/en_US/fbevents.js');\n" +
    " fbq('init', '2794392110843790'); \n" +
    "fbq('track', 'PageView');\n" +
    '</script>\n' +
    '<noscript>\n' +
    ' <img height="1" width="1" \n' +
    'src="https://www.facebook.com/tr?id=2794392110843790&ev=PageView\n' +
    '&noscript=1"/>\n' +
    '</noscript>\n' +
    '<!-- End Facebook Pixel Code -->';
  document.head.insertAdjacentHTML('beforeend', html);
}
