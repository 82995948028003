export interface Lang {
  en: string;
  fr: string;
}
export enum Language {
  en = 'en',
  fr = 'fr'
}

export interface LoginRequestBody {
  id: string;
  tenantid?: number;
  destination?: string;
  pin?: string;
  tenantcode?: string;
}

export enum Destination {
  email = 'email',
  phone = 'phone',
  sms = 'sms'
}

export enum IdType {
  email = 'email',
  employeeid = 'employeeid'
}

export interface CommonResponse {
  message: string;
}

export interface CommonRes {
  message: string;
}

export interface RequestPinRes {
  message: string;
  testing_message?: string;
}

export interface MessageWithStatus {
  message: string;
  status: number;
}

export interface UserInfo {
  user_token: string;
  user_id: number;
  max_weekly_hours?: number;
  timestamp: number;
  tenantid?: number;
  user_roles?: string[];
  default_language?: string;
}

export interface NotificationRes {
  message: string;
  data: Notification[];
}

export interface Notification {
  id: number;
  attr: NotificationAttr;
  created_dt: string;
  time?: string;
}

export interface NotificationAttr {
  notification: NotificationListRes;
  data: NotificationListData;
  fcm_options: {
    link: string;
  };
  actions?: { action: string; title: string }[];
}

export interface NotificationListRes {
  title: string;
  body: string;
  image?: string;
}

export interface NotificationListData {
  queue_position_id?: number;
  domain_sessionid?: string;
  domain_userid?: string;
  url?: string;
  priority: string;
  type: string;
}

export interface NotificationLink {
  destination: string;
  id: number;
}

export const FooterMenus = [
  {
    url: '/dashboard',
    class: 'icon__home',
    type: 'dashboard'
  },
  {
    url: '/dashboard/schedule',
    class: 'icon__calendar',
    type: 'schedule'
  },
  {
    url: 'chat',
    class: 'icon__chat',
    type: 'chat'
  },
  {
    url: '/dashboard/taskList',
    class: 'icon__task',
    type: 'task'
  }
];

export enum MenuTypes {
  dashboard = 'dashboard',
  schedule = 'schedule',
  chat = 'chat',
  task = 'task',
  save = 'save'
}

export enum ShiftIn {
  dashboard = 'dashboard',
  schedule = 'dashboard/schedule'
}

export enum PopupReturnValue {
  PrimaryButton = 'P',
  SecondaryButton = 'S',
  XButton = 'X'
}

export interface TokenReq {
  user_id?: number;
  token: string;
  attr?: string;
}

export interface TokenAttr {
  [key: string]: string;
}

export interface RegistrationRes {
  status: number;
  data: RegistrationData;
}

export interface RegistrationData {
  httpcode: number;
  data: LaunchLink;
  status: number;
}

export interface LaunchLink {
  launchLink: string;
}

export enum RedirectTypes {
  lms = 'lms',
  survey = 'survey',
  checkin = 'checkin',
  checkout = 'checkout',
  shift = 'shift',
  availability = 'availability',
  external = 'external'
}

export enum FooterType {
  task = 'task',
  chat = 'chat'
}

export interface CurrentClient {
  tenantName?: string;
  tenantCode?: string;
  tenant_id?: number;
  queueId?: number;
  hero_image?: string;
  logo?: string;
  title?: string;
  subtitle?: string;
  colors?: {
    'client-color': string;
    'button-color': string;
  };
}

// This is the API response structure, please use CurrentClient
export interface CurrentClientResponse {
  tenantname?: string;
  tenant_id?: number;
  queueId?: number;
  hero_image?: string;
  logo?: string;
  title?: string;
  subtitle?: string;
  colors?: {
    'client-color': string;
    'button-color': string;
  };
}

export interface LoginInfoRes {
  userId: string;
  message: string;
}

export enum BookingConfig {
  bookingFormConfig = 'booking-form-config',
  roomName = 'roomName'
}

export const ClientsName: string[] = [
  'MPWR',
  'Royfoss',
  'Cellcom',
  'Queensshop'
];

export const ClientsNameHasInfo: string[] = [
  'mpwr',
  'royfoss',
  'cellcom',
  'gentraf',
  'mccarthy',
  'demo',
  'queensshop',
  'optimy',
  'thescaffeogroup',
  'yangsjewellery'
];

export const MockDataRooms = {
  Local: {
    pending: [
      'CHc606187e0e6b47f387a55f42d7e83a5b',
      'CH73db7c99842e4a15b56189db61cc535f',
      'CHc693bc6b34194d93b1694d2cc7ef51f7',
      'CH72e2056a6254476e817dbfd984d73b13'
    ],
    inProgress: 'CH793b2593356a49b88633117950a9a80d',
    scheduled: 'CHf437a6defb55440c88c906add60f27ca'
  },
  Devfront: {
    pending: [
      'CHc606187e0e6b47f387a55f42d7e83a5b',
      'CH73db7c99842e4a15b56189db61cc535f',
      'CHc693bc6b34194d93b1694d2cc7ef51f7',
      'CH72e2056a6254476e817dbfd984d73b13'
    ],
    inProgress: 'CH793b2593356a49b88633117950a9a80d',
    scheduled: 'CHf437a6defb55440c88c906add60f27ca'
  },
  QA: {
    pending: [
      'CH206f0ce22bbc467e8a499d6b18a60e4a',
      'CH062e1fa1436f47aaa1fd90381dd31912',
      'CH99eb603fba714af7a874260edf2d2e88',
      'CHc76997cd5bbf4c21aee0f633dc996d4e'
    ],
    inProgress: 'CH08938e5de56b42228b70add825ce93ec',
    scheduled: 'CH785f0bf1e5324f6a8b11d630110195dd'
  },
  UAT: {
    pending: [
      'CHedc7a31e16814a75ade3cafa2a7d3ea0',
      'CHedebce6511b74bfb88b296ff1b0ba6c2',
      'CH0bd120fff2b343dbabf1403a284d0d2a',
      'CH97a0ffd36f8a4eeea28272f4727a3f3b'
    ],
    inProgress: 'CHedebce6511b74bfb88b296ff1b0ba6c2',
    scheduled: 'CHc2e9aac8d63c46bd9d1618265d6c9442'
  },
  Production: {
    pending: [
      'CH956eb6c848464cdf87e0adb61075876d',
      'CH80c594392ca946e3a06664db9188263c',
      'CH7984887980a54b84bf45d3434e4759eb',
      'CHb938358541964709aa277bd857503062'
    ],
    inProgress: 'CH32df20436eec4a409fbc58421cd18217',
    scheduled: 'CH249cc8db0b0e4df5bbc023c3b8d56df3'
  },
  Devback: {
    pending: [
      'CH70d98b4d27b44d4ab87593740a84f03b',
      'CH2421af2e656d42768f86581bdabfd2f8',
      'CH595eda73a2b644ef83ccfd959e3ae196',
      'CHed9d6b07ad844026a1821602578ae7d8'
    ],
    inProgress: 'CH2421af2e656d42768f86581bdabfd2f8',
    scheduled: 'CHed9d6b07ad844026a1821602578ae7d8'
  }
};

export interface BuildUpdate {
  pwa: string;
}
