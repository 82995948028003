import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { takeUntil, catchError } from 'rxjs/operators';
import {
  ClientsName,
  CurrentClient,
  TokenReq,
  UserInfo
} from 'src/app/core/constants/common.enum';
import { Clients, Constants } from 'src/app/core/constants/constants';
import { PwaService } from 'src/app/core/services/pwa-service/pwa.service';
import { UtilsService } from 'src/app/core/services/utils/utils.service';
import { environment } from '../../../environments/environment';
import { UserDetails } from '../../core/constants/dashboard-modal';
import { optimyRoutes } from '../../core/constants/optimyRoutes';
import { Roles } from '../../core/constants/schedule-config';
import { RoomData } from '../../core/constants/video-config';
import { RoleAccessPipe } from '../../core/pipes/roleAccess.pipe';
import { HttpWrapperService } from '../../core/services/http-service/http-wrapper.service';
import { VideoChatService } from '../../core/services/video-chat/video-chat.service';
import * as appActions from '../../state/app.actions';
import { getUserDetailsSelector } from '../../state/app.selectors';
import * as AppState from '../../state/app.state';
import { CallService } from '../../modules/root/new-queue/modules/call/services/call-service/call.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit, OnDestroy {
  constant = Constants;
  isDevice: boolean;
  currentUrl: string;
  userDetails: UserDetails;
  token: string;
  showNewInChat = false;
  roles = Roles;
  constants = Constants;
  clients = Clients;
  client: CurrentClient;
  clientsName = ClientsName;
  appVersion: string;
  roomInfo: RoomData;
  isInviteCalled = false;

  destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private callService: CallService,
    private httpClientWrapper: HttpWrapperService,
    private utils: UtilsService,
    private translate: TranslateService,
    private toastr: ToastrService,
    private sw: PwaService,
    private appStore: Store<AppState.State>,
    private roleAccessPipe: RoleAccessPipe
  ) {}

  ngOnInit(): void {
    this.appVersion = environment.production
      ? Constants.version
      : `${Constants.version} - (${environment.appVersion.split('.')[2]})`;
    this.getStoreInformation();

    // TODO change the client to the state management
    this.client = this.utils.getClient();
    this.currentUrl = this.router.url;
    this.isDevice = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

    //Now this event is handled on new header component

    // this.httpClientWrapper.unauthorizedCall
    //   .pipe(takeUntil(this.destroy$))
    //   .subscribe({
    //     next: (data: boolean) => {
    //       if (
    //         this.parentComponent === 'redirect' &&
    //         data &&
    //         this.activatedRoute.snapshot.queryParams?.code &&
    //         !this.isInviteCalled
    //       ) {
    //         console.log("get rooom info header ~~~~~~~~~~");
    //         this.isInviteCalled = true;
    //         this.callService
    //           .getRoomInfo(this.activatedRoute.snapshot.queryParams?.code)
    //           .pipe(
    //             catchError(() => {
    //               this.toastr.error(
    //                 this.translate.instant('save.callNotInProgress')
    //               );
    //               this.router.navigateByUrl(Constants.routes.save);
    //               return null;
    //             })
    //           )
    //           .subscribe((roomInfo: RoomData) => {
    //             this.roomInfo = roomInfo;
    //             if (this.roomInfo?.user_token) {
    //               const userInfo: UserInfo = {
    //                 user_token: roomInfo?.user_token,
    //                 user_id: roomInfo?.user_id,
    //                 timestamp: roomInfo?.timestamp
    //               };
    //               this.utils.setUserInfo(userInfo);
    //               this.appStore.dispatch(
    //                 new appActions.LoadUserDetailsAction(userInfo?.user_id)
    //               );
    //             } else {
    //               this.signOut(true);
    //             }
    //           });
    //       }
    //     }
    //   });
  }

  ngOnDestroy() {
    this.destroy$.next(true);
  }

  private getStoreInformation(): void {
    this.appStore
      .pipe(select(getUserDetailsSelector), takeUntil(this.destroy$))
      .subscribe((response: UserDetails) => {
        this.userDetails = response;
      });
  }

  signOut(unauthorized: boolean) {
    this.token = this.utils.getToken();
    if (this.token) {
      const tokenReq: TokenReq = {
        token: this.token
      };
      this.utils.unAssignToken(tokenReq).subscribe(
        (response) => {
          console.log('notification enabled', response?.message);
          localStorage.removeItem(Constants.notification);
          localStorage.removeItem(Constants.deviceToken);
          this.sw.stopPing();
        },
        (error) => {
          console.log('error in notification', error);
        }
      );
    }
    localStorage.removeItem(Constants.userInfo);
    if (unauthorized) {
      this.router.navigate([Constants.routes.login], {
        queryParams: { redirectURL: location.href.replace(location.origin, '') }
      });
    } else {
      this.router.navigateByUrl(Constants.routes.login);
    }
  }

  navigate(url) {
    this.router.navigateByUrl(url);
  }

  settings() {
    let url;
    if (this.roleAccessPipe.transform(this.roles.admin)) {
      url = optimyRoutes.settingsOutlet.bookingConfig;
    } else {
      url = optimyRoutes.settingsOutlet.notificationConfig;
    }
    this.router.navigate([
      Constants.routes.dashboard,
      Constants.routes.settings,
      url
    ]);
  }

  checkClients() {
    return !this.clientsName.includes(this.client?.tenantName);
  }

  navigateToDashboard(): void {
    this.router.navigate([Constants.routes.dashboard]);
  }
}
