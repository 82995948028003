import { Pipe, PipeTransform } from '@angular/core';
import { UserDetails } from '../constants/dashboard-modal';
import { UtilsService } from "../services/utils/utils.service";

@Pipe({
  name: 'roleAccess'
})
export class RoleAccessPipe implements PipeTransform {
  userDetails: UserDetails;

  constructor(private utils: UtilsService) {
  }


  transform(role: string): boolean {
    const rolesList = this.utils.getUserInfo();
    return rolesList?.user_roles?.includes(role);
  }

}
