import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import {
  InAppNotification,
  InAppNotificationType
} from './in-app-notification.model';
import { InAppNotificationService } from './in-app-notification.service';

@Component({
  selector: 'app-in-app-notification',
  templateUrl: './in-app-notification.component.html',
  styleUrls: ['./in-app-notification.component.scss']
})
export class InAppNotificationComponent implements OnInit, OnDestroy {
  id = 'default-notification-id';
  fade = true;

  notifications: InAppNotification[] = [];
  destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(
    private router: Router,
    private inAppNotificationService: InAppNotificationService
  ) {}

  ngOnInit() {
    this.inAppNotificationService
      .onAddNotification()
      .pipe(takeUntil(this.destroy$))
      .subscribe((notification) => {
        if (!notification.message) {
          this.notifications = this.notifications.filter(
            (x) => x.keepAfterRouteChange
          );
          this.notifications.forEach((x) => delete x.keepAfterRouteChange);
          return;
        }

        this.notifications.push(notification);

        if (notification.autoClose) {
          setTimeout(
            () => this.removeNotification(notification),
            notification.timeoutToClose
          );
        }
      });

    // clear alerts on location change
    this.router.events.pipe(takeUntil(this.destroy$)).subscribe((event) => {
      if (event instanceof NavigationStart) {
        this.inAppNotificationService.clear(this.id);
      }
    });
  }

  ngOnDestroy() {
    this.destroy$.next(true);
  }

  removeNotification(notification: InAppNotification) {
    if (!this.notifications.includes(notification)) return;

    if (this.fade) {
      this.notifications.find((x) => x === notification).fade = true;
      setTimeout(() => {
        this.notifications = this.notifications.filter(
          (x) => x !== notification
        );
      }, 250);
    } else {
      this.notifications = this.notifications.filter((x) => x !== notification);
    }
  }

  cssClass(notification: InAppNotification) {
    if (!notification) return;
    const classes = ['notification'];
    const InAppNotificationTypeClass = {
      [InAppNotificationType.Success]: 'notification-success',
      [InAppNotificationType.Error]: 'notification-danger',
      [InAppNotificationType.Info]: 'notification-info',
      [InAppNotificationType.Warning]: 'notification-warning'
    };
    classes.push(InAppNotificationTypeClass[notification.type]);
    if (notification.fade) {
      classes.push('fade');
    }
    return classes.join(' ');
  }

  buttonAction(notification: InAppNotification) {
    notification.onButtonAction.next(notification);
  }

  bodyAction(notification: InAppNotification) {
    notification.onBodyAction.next(notification);
  }

  onCancelButtonAction(notification: InAppNotification) {
    notification.onCancelButtonAction.next(notification);
  }
}
