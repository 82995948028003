import { Subject } from 'rxjs';

export class InAppNotification {
  id: string;
  type: InAppNotificationType;
  message: string;
  autoClose: boolean = true;
  keepAfterRouteChange: boolean;
  fade: boolean;
  timeoutToClose: number = 3000;
  buttonLabel: string;
  data: any;
  onButtonAction: Subject<InAppNotification>;
  onBodyAction: Subject<InAppNotification>;
  onCancelButtonAction?: Subject<InAppNotification>;
  cancelLabel?: string;
  inviteCode?: string;

  constructor(init?: Partial<InAppNotification>) {
    Object.assign(this, init);
  }
}

export enum InAppNotificationType {
  Success,
  Error,
  Info,
  Warning
}
