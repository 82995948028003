<div *ngIf="!isDecline">
<app-header></app-header>
<div class="save__redirect">
  <div class="container-xl">
    <div class="msg d-flex align-items-center justify-content-center" [innerHTML]="'save.redirectMsg' | translate"></div>
  </div>
  <div *ngIf="showGuestError" class="msg d-flex align-items-center justify-content-center"
       [innerHTML]="'save.callSessionLinkExpired' | translate"></div>
</div>
</div>
<div *ngIf="isDecline">
  <ngx-spinner type="ball-scale-multiple"></ngx-spinner>
</div>
