export interface ScheduleDays {
  date: string;
  max_daily_hours: number;
  slots: SlotInfo[];
}

export interface SlotInfo {
  id?: number;
  start: string;
  end: string;
}

export enum Roles {
  RL = 'RL',
  ML = 'ML',
  Chat = 'Chat',
  admin = 'SAVE Admin',
  outbound = 'SAVE Outbound',
  recording = 'SAVE Recording',
  siteManager = 'SAVE Site Manager',
  agent = 'SAVE Agent'
}
export const SpecificVehicleTypes: string[] = ['Yes', 'No'];

export const VehicleTypesOptions: string[] = [
  'BUICK ENCLAVE',
  'BUICK ENCORE GX',
  'BUICK ENVISION',
  'CADILLAC CT4',
  'CADILLAC CT4-V',
  'CADILLAC CT5',
  'CADILLAC CT5-V',
  'CADILLAC ESCALADE',
  'CADILLAC ESCALADE ESV',
  'CADILLAC XT4',
  'CADILLAC XT5',
  'CADILLAC XT6',
  'CADILLAC XTS',
  'CHEVROLET BLAZER',
  'CHEVROLET CAMARO',
  'CHEVROLET COLORADO',
  'CHEVROLET CORVETTE',
  'CHEVROLET EQUINOX',
  'CHEVROLET EXPRESS',
  'CHEVROLET MALIBU',
  'CHEVROLET SILVERADO 1500',
  'CHEVROLET SILVERADO 3500',
  'CHEVROLET SPARK',
  'CHEVROLET TRAILBLAZER',
  'CHEVROLET TRAVERSE',
  'CHEVROLET TRAX',
  'GMC ACADIA',
  'GMC CANYON',
  'GMC SIERRA 1500',
  'GMC TERRAIN',
  'GMC YUKON'
];

export interface ScheduleShift {
  draft: number;
  locked: number;
  scheduled_end: string;
  scheduled_start: string;
  scheduleid: number;
  shiftid: number;
  userid: number;
  venueid: number;
  attr: ShiftAttr;
  schedule?: string;
  userInfo?: ScheduleUsers;
  valid: number;
}

export interface ShiftAttr {
  jobtitle: string;
  shifttype: string;
  trainingtype: string;
}

export interface ScheduleUsers {
  availability: ScheduleAvailability;
  email: string;
  employeeid: string;
  first_name: string;
  last_name: string;
  id: number;
  phone: string;
  labels: string[];
  performance: UserPerformance;
}

export interface UserPerformance {
  AvS: number;
  SPH: number;
  APH: number;
  DPPH: number;
}

export interface ScheduleAvailability {
  max_weekly_hours: string;
  daily: ScheduleDays[];
  hours_scheduled: string | number;
}

export interface ScheduleVenues {
  address_city: string;
  address_country: string;
  address_latitude: number;
  address_line_1: string;
  address_line_2: string;
  address_longitude: number;
  address_postal: string;
  address_province: string;
  allocation_region: string;
  client: string;
  location_attr: string;
  locationcode: string;
  locationid: number;
  name: string;
  number: number;
  phone: number;
  program: string;
  region: string;
  venue_attr: VenueAttr;
  venueid: number;
  full_name: string;
}

export interface VenueAttr {
  program_category: string;
}

export enum ShiftOptions {
  edit = 'Edit',
  drag = 'Drag',
  new = 'New'
}

export enum ConfirmationShiftOptn {
  confirm = 'confirm'
}
