import { Inject, Injectable } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { Observable, Subject, throwError } from 'rxjs';
import { HttpWrapperService } from 'src/app/core/services/http-service/http-wrapper.service';
import { Constants } from 'src/app/core/constants/constants';
import {
  CommonRes,
  CommonResponse,
  CurrentClient,
  Language,
  LoginInfoRes,
  LoginRequestBody,
  Notification,
  NotificationRes,
  RequestPinRes,
  TokenReq,
  UserInfo
} from 'src/app/core/constants/common.enum';
import { catchError, map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { CallData, RoomKeys } from '../../constants/video-config';
import { NgbDateStruct, NgbTimeStruct } from '@ng-bootstrap/ng-bootstrap';
import {
  GaussianBlurBackgroundProcessor,
  ImageFit,
  VirtualBackgroundProcessor
} from '@twilio/video-processors';
import { environment } from '../../../../environments/environment';
import { UpdateGuestInfo } from '../../constants/outbound-config';
import { AngularFireMessaging } from '@angular/fire/compat/messaging';
import { DomSanitizer } from '@angular/platform-browser';
import { Image } from 'exceljs';
import * as moment from 'moment';
import { QueueCall } from '../../../modules/root/new-queue/modals/queue.config';

@Injectable({
  providedIn: 'root'
})
export class UtilsService {
  notificationList: Notification[];
  updateGuestStatus$ = new Subject<UpdateGuestInfo>();
  inAppNotification$ = new Subject<boolean>();
  updateNotificationList$ = new Subject<boolean>();
  resetLastMessageCount$ = new Subject<boolean>();
  audioPlayed = false;
  soundMp3 = new Audio('/assets/audio/new-message-audio.mp3');
  firebaseNotification$ = new Subject();

  constructor(
    private httpClientWrapper: HttpWrapperService,
    private http: HttpClient,
    private angularFireMessaging: AngularFireMessaging,
    private sanitizer: DomSanitizer,
    @Inject(DOCUMENT) private _document: HTMLDocument
  ) {}

  requestPin(
    loginRequestBody: LoginRequestBody
  ): Observable<RequestPinRes | any> {
    return this.httpClientWrapper
      .post('sample', Constants.apiPaths.loginRequestPin, loginRequestBody)
      .pipe(
        map((res) => {
          return res;
        })
      )
      .pipe(
        catchError((e) => {
          return throwError(e);
        })
      );
  }

  validatePin(loginRequestBody: LoginRequestBody): Observable<UserInfo> {
    return this.httpClientWrapper
      .post('sample', Constants.apiPaths.validatePin, loginRequestBody)
      .pipe(
        map((res: UserInfo) => {
          return res;
        })
      )
      .pipe(
        catchError((e) => {
          return throwError(e);
        })
      );
  }

  assignToken(tokenReq: TokenReq): Observable<CommonRes | any> {
    return this.httpClientWrapper
      .post('sample', Constants.apiPaths.assignToken, tokenReq)
      .pipe(
        map((res: CommonRes) => {
          return res;
        })
      )
      .pipe(
        catchError((e) => {
          return throwError(e);
        })
      );
  }

  unAssignToken(tokenReq: TokenReq): Observable<CommonRes | any> {
    return this.httpClientWrapper
      .post('sample', Constants.apiPaths.unAssignToken, tokenReq)
      .pipe(
        map((res) => {
          return res;
        })
      )
      .pipe(
        catchError((e) => {
          return throwError(e);
        })
      );
  }

  getNotification(userId: string | number): Observable<Notification[]> {
    return this.httpClientWrapper
      .post('sample', `${Constants.apiPaths.getNotification}/${userId}`, {})
      .pipe(
        map((res: NotificationRes) => {
          return res?.data;
        })
      )
      .pipe(
        catchError((e) => {
          return throwError(e);
        })
      );
  }

  dismissNotification(
    notification_ids: string
  ): Observable<CommonResponse | any> {
    return this.httpClientWrapper
      .post('sample', Constants.apiPaths.dismissNotification, {
        notification_ids
      })
      .pipe(
        map((res: CommonResponse) => {
          return res;
        })
      )
      .pipe(
        catchError((e) => {
          return throwError(e);
        })
      );
  }

  refreshToken(userInfo: UserInfo): Observable<string | any> {
    return this.httpClientWrapper
      .post(
        'sample',
        `${Constants.apiPaths.refreshToken}/${userInfo?.user_token}`,
        {}
      )
      .pipe(
        map((res: UserInfo) => {
          return res;
        })
      )
      .pipe(
        catchError((e) => {
          return throwError(e);
        })
      );
  }

  setUserInfo(userInfo: UserInfo) {
    if (!userInfo) {
      localStorage.removeItem(Constants.userInfo);
    } else {
      const user = JSON.stringify(userInfo);
      localStorage.setItem(Constants.userInfo, user);
    }
  }

  getUserInfo(): UserInfo {
    const userInfo = localStorage.getItem(Constants.userInfo);
    return JSON.parse(userInfo);
  }

  userHasRole(role: string): boolean {
    const userInfo = this.getUserInfo();
    return userInfo?.user_roles?.includes(role);
  }

  setLastUpdateTime(date: string) {
    localStorage.setItem(Constants.lastUpdatedTime, date);
  }

  getLastUpdateTime(): string {
    return localStorage.getItem(Constants.lastUpdatedTime);
  }

  setDeviceToken(token) {
    localStorage.setItem(Constants.deviceToken, token);
  }

  getToken(): string {
    return localStorage.getItem(Constants.deviceToken);
  }

  setAppInfo(clientName) {
    const faviconPath = 'assets/icons/Common.favicon.ico';
    this._document
      ?.getElementById('appFavicon')
      ?.setAttribute('href', faviconPath);
    this._document.head.querySelector('title').innerText =
      environment.production ? clientName : `${clientName} ${environment.name}`;
  }

  updateFavIcon(inCall: boolean) {
    const faviconPath = inCall
      ? 'assets/icons/Common.LiveCallEngaged.favicon.png'
      : 'assets/icons/Common.favicon.ico';
    this._document
      ?.getElementById('appFavicon')
      ?.setAttribute('href', faviconPath);
  }

  setLanguage(lang) {
    localStorage.setItem('language', lang);
  }

  getLanguage() {
    const lang = localStorage.getItem('language');
    return lang ? lang : Language.en;
  }

  setGuestInfo(guestInfo) {
    localStorage.setItem(Constants.guestInfo, JSON.stringify(guestInfo));
  }

  clearGuestInfo() {
    localStorage.removeItem(Constants.guestInfo);
  }

  getGuestInfo(): QueueCall {
    return JSON.parse(localStorage.getItem(Constants.guestInfo));
  }

  setVideoRoomStatus(keys: string[], values: any) {
    const state = Constants.roomStatus;
    const roomState = JSON.parse(<string>localStorage.getItem(state)) || {};
    keys.forEach(function (key, index) {
      roomState[key] = values[index];
    });
    localStorage.setItem(state, JSON.stringify(roomState));
  }

  clearVideoRoomStatus() {
    localStorage.removeItem(Constants.roomStatus);
  }

  removeKeyFromRoomStatus(keys: string[]) {
    const state = Constants.roomStatus;
    const roomState = JSON.parse(<string>localStorage.getItem(state)) || {};
    keys.forEach((key) => {
      delete roomState[key];
    });
    localStorage.setItem(state, JSON.stringify(roomState));
  }

  getVideoRoomStatus(getAll: boolean, key?: string): any {
    const callState = JSON.parse(
      <string>localStorage.getItem(Constants.roomStatus)
    );
    return callState ? (getAll ? callState : callState[key]) : null;
  }

  setClient(client: CurrentClient) {
    localStorage.setItem(Constants.client, JSON.stringify(client));
  }

  setAndGetBlurBackground(value?: boolean, isBlurBackground?: boolean) {
    const blurBackgroundKey = 'blurBackground';
    if (value) {
      localStorage.setItem(blurBackgroundKey, JSON.stringify(isBlurBackground));
      return;
    }
    return JSON.parse(localStorage.getItem(blurBackgroundKey));
  }

  setAndGetVirtualBackground(value?: boolean, isVirtualBackground?: boolean) {
    const virtualBackgroundKey = 'virtualBackground';
    if (value) {
      localStorage.setItem(
        virtualBackgroundKey,
        JSON.stringify(isVirtualBackground)
      );
      return;
    }
    return JSON.parse(localStorage.getItem(virtualBackgroundKey));
  }

  setMediaDevicesInLocalStorage(keys: string[], values: any) {
    const state = Constants.mediaDevices;
    const mediaDevices = JSON.parse(<string>localStorage.getItem(state)) || {};
    keys.forEach(function (key, index) {
      mediaDevices[key] = values[index];
    });
    localStorage.setItem(state, JSON.stringify(mediaDevices));
  }

  getMediaDevicesFromLocalStorage(getAll: boolean, key?: string): any {
    const devices = JSON.parse(
      <string>localStorage.getItem(Constants.mediaDevices)
    );
    return devices ? (getAll ? devices : devices[key]) : null;
  }

  getClient(): CurrentClient {
    return JSON.parse(localStorage.getItem(Constants.client));
  }

  setLoginInfo(loginInfo: LoginInfoRes) {
    localStorage.setItem(Constants.loginInfo, JSON.stringify(loginInfo));
  }

  getLoginInfo(): LoginInfoRes {
    return JSON.parse(localStorage.getItem(Constants.loginInfo));
  }

  removeLoginInfo() {
    localStorage.removeItem(Constants.loginInfo);
  }

  ngbStructToDate(date: NgbDateStruct): Date {
    return new Date(date.year, date.month - 1, date.day);
  }

  ngbStructToNormalDate(date: NgbDateStruct): string {
    return `${date.year}/${date.month < 10 ? `0${date.month}` : date.month}/${
      date.day < 10 ? `0${date.day}` : date.day
    }`;
  }

  ngbStructToNormalTime(time: NgbTimeStruct): string {
    return `${time.hour}:${time.minute}:${time.second}`;
  }

  async checkAndSetVideoBlurBackground(
    blurBackgroundStatus: boolean,
    currentVideoTrack: any
  ) {
    const blurFilterRadius = 10;
    const maskBlurRadius = 5;
    const assetsPath = '';
    let blurProcessor = new GaussianBlurBackgroundProcessor({
      assetsPath,
      maskBlurRadius,
      blurFilterRadius
    });
    await blurProcessor.loadModel();
    await this.setProcessor(
      blurProcessor,
      blurBackgroundStatus,
      currentVideoTrack
    );
  }

  async checkAndSetVideoVirtualBackground(
    blurBackgroundStatus: boolean,
    currentVideoTrack: any
  ) {
    const virtualImgUrl = this.getVideoRoomStatus(
      false,
      RoomKeys.virtualBackgroundImg
    );
    let img = new Image();
    img.crossOrigin = 'Anonymous';
    img.src = virtualImgUrl;
    const maskBlurRadius = 2;
    img.onload = async () => {
      let virtualProcessor = new VirtualBackgroundProcessor({
        assetsPath: '/',
        backgroundImage: img,
        maskBlurRadius,
        fitType: ImageFit.Cover
      });
      await virtualProcessor.loadModel();
      await this.setProcessor(
        virtualProcessor,
        blurBackgroundStatus,
        currentVideoTrack
      );
    };
  }

  async setProcessor(processor: any, value: boolean, track: any) {
    if (!value && track?.processor) {
      track.removeProcessor(track.processor);
      return true;
    } else {
      track.addProcessor(processor);
      return true;
    }
  }

  updateGuestStatus(domainId: string, queueId: number) {
    this.updateGuestStatus$.next({ domainId, queueId });
  }

  onClickOfInAppNotification(value: boolean) {
    this.inAppNotification$.next(value);
  }

  updateNotificationList(value: boolean) {
    this.updateNotificationList$.next(value);
  }

  receiveMessage() {
    this.angularFireMessaging.messages.subscribe((payload: any) => {
      this.firebaseNotification$.next(payload?.data);
      if (!environment.production) {
        console.log('new message received. ', payload);
      }
    });
  }

  getGuestIdentity(identity: string, user: QueueCall) {
    let guestName;
    if (user.full_name) {
      guestName = `${identity}_${user.full_name.split(' ')[0]}`;
    } else if (user.email) {
      guestName = `${identity}_${user.email}`;
    } else {
      guestName = identity;
    }
    return guestName;
  }

  showMobileView(): boolean {
    return window.innerWidth < 1025 && window.innerHeight < 1025;
  }

  updateBrowserTitle(isReset: boolean, messageCount: number) {
    let title = document.title;
    if (isReset && title.includes('(')) {
      document.title = title.substring(title.lastIndexOf(')') + 1);
    } else if (messageCount > 0) {
      title = title.substring(title.lastIndexOf(')') + 1);
      document.title = `(${messageCount}) ${title}`;
      this.playAlertAudio();
    }
  }

  playAlertAudio() {
    if (!this.audioPlayed) {
      this.soundMp3
        .play()
        .then(() => {})
        .catch(() => {});
      this.audioPlayed = true;
      setTimeout(() => {
        this.audioPlayed = false;
      }, 5000);
    }
  }

  getTime(callStarted) {
    const format = 'DD/MM/YYYY HH:mm:ss';
    const now = moment(new Date()).format(format);
    const then = moment.unix(callStarted).format(format);
    const ms = moment(now, format).diff(moment(then, format));
    const d = moment.duration(ms);
    return (
      `0${Math.floor(d.asHours())}` +
      moment.utc(ms).locale('en').format(':mm:ss')
    );
  }

  moveCallStoreValuesToLocalForNewTab(queueId: string): void {
    const callData: CallData = {
      roomInfo: this.getVideoRoomStatus(true),
      guestInfo: this.getGuestInfo(),
      blurBackground: this.setAndGetBlurBackground(false),
      virtualBackground: this.setAndGetVirtualBackground(false)
    };
    localStorage.setItem(queueId, JSON.stringify(callData));
    sessionStorage.clear();
  }

  moveBackCallStoreValuesToSessionForNewTab(queueId: string): void {
    const callData: CallData = JSON.parse(localStorage.getItem(queueId));
    if (callData) {
      sessionStorage.setItem(
        Constants.roomStatus,
        JSON.stringify(callData.roomInfo)
      );
      sessionStorage.setItem(
        Constants.guestInfo,
        JSON.stringify(callData.guestInfo)
      );
      sessionStorage.setItem(
        'blurBackground',
        JSON.stringify(callData.blurBackground)
      );
      sessionStorage.setItem(
        'virtualBackground',
        JSON.stringify(callData.virtualBackground)
      );
      localStorage.removeItem(`${queueId}`);
    }
  }

  checkCurrentClientIsDelonghi() {
    const clientDetails = this.getClient();
    return (
      clientDetails?.tenantCode?.toLowerCase() === 'delonghi' ||
      clientDetails?.tenantName?.toLowerCase() === 'delonghi' ||
      clientDetails?.tenantName?.toLowerCase() === 'delonghi2'
    );
  }

  setIsTenantCode(value) {
    localStorage.setItem(Constants.noTenantCode, JSON.stringify(value));
  }

  getIsTenantCode() {
    return JSON.parse(localStorage.getItem(Constants.noTenantCode));
  }
}
