// const assets = '/assets/images/icons_/';
const assets = '/assets/images/icons/';
const newAssets = '/assets/images/new-icons/';

export const svgIcons = [
  { iconName: 'active-outreach', iconPath: `${assets}active-outreach.svg` },
  { iconName: 'addSlot', iconPath: `${assets}add-slot.svg` },
  { iconName: 'agent-chat-black', iconPath: `${assets}agent-chat-black.svg` },
  { iconName: 'agent-offline', iconPath: `${assets}agent-offline.svg` },
  { iconName: 'agent-onCall', iconPath: `${assets}agent-on-call.svg` },
  { iconName: 'agent-online', iconPath: `${assets}agent-online.svg` },
  { iconName: 'arrow-right', iconPath: `${assets}arrow-right.svg` },
  { iconName: 'big-arrow-left', iconPath: `${assets}big-arrow-left.svg` },
  { iconName: 'calendar', iconPath: `${assets}calendar.svg` },
  { iconName: 'cam', iconPath: `${assets}video.svg` },
  {
    iconName: 'chat-msg-failed',
    iconPath: `${assets}video-chat-msg-failed.svg`
  },
  {
    iconName: 'chat-msg-pending',
    iconPath: `${assets}video-chat-msg-pending.svg`
  },
  { iconName: 'chat-msg-sent', iconPath: `${assets}video-chat-msg-sent.svg` },
  { iconName: 'chat-new', iconPath: `${assets}chat-new.svg` },
  { iconName: 'chat-toggle', iconPath: `${assets}chat-toggle.svg` },
  { iconName: 'contact', iconPath: `${assets}contact.svg` },
  { iconName: 'control', iconPath: `${assets}control.svg` },
  { iconName: 'countdown', iconPath: `${assets}countdown.svg` },
  { iconName: 'delete', iconPath: `${assets}delete.svg` },
  { iconName: 'email', iconPath: `${assets}email.svg` },
  { iconName: 'end-call', iconPath: `${assets}end-call.svg` },
  { iconName: 'file-video', iconPath: `${assets}file-video.svg` },
  { iconName: 'file', iconPath: `${assets}file.svg` },
  { iconName: 'gears', iconPath: `${assets}gears.svg` },
  { iconName: 'guest-observe', iconPath: `${assets}outbound-enabled.svg` },
  { iconName: 'history', iconPath: `${assets}history.svg` },
  { iconName: 'inbox', iconPath: `${assets}inbox.svg` },
  { iconName: 'list', iconPath: `${assets}list.svg` },
  { iconName: 'medal', iconPath: `${assets}medal.svg` },
  { iconName: 'memo', iconPath: `${assets}memo.svg` },
  { iconName: 'mic-off-icon', iconPath: `${assets}mic-off-icon.svg` },
  { iconName: 'mic-off', iconPath: `${assets}mic-off.svg` },
  { iconName: 'mic-on-icon', iconPath: `${assets}mic-on-icon.svg` },
  { iconName: 'mic-on', iconPath: `${assets}mic-on.svg` },
  { iconName: 'mic', iconPath: `${assets}mic.svg` },
  { iconName: 'microphone', iconPath: `${assets}microphone.svg` },
  { iconName: 'observeOff', iconPath: `${assets}observe-off.svg` },
  { iconName: 'observeOn', iconPath: `${assets}observe-on.svg` },
  { iconName: 'optimy-logo', iconPath: `${assets}optimy-logo.svg` },
  { iconName: 'optimyAgent', iconPath: `${assets}optimy-agent.svg` },
  { iconName: 'outbound-device', iconPath: `${assets}outbound-device.svg` },
  {
    iconName: 'outbound-session-details',
    iconPath: `${assets}outbound-session-details-blue.svg`
  },
  {
    iconName: 'outbound-video-call',
    iconPath: `${assets}outbound-video-call.svg`
  },
  { iconName: 'page-history', iconPath: `${assets}page-history.svg` },
  { iconName: 'page-history', iconPath: `${assets}page-history.svg` },
  { iconName: 'paper-plane', iconPath: `${assets}paper-plane.svg` },
  { iconName: 'people-email', iconPath: `${assets}people-email.svg` },
  { iconName: 'people-info', iconPath: `${assets}people-info.svg` },

  {
    iconName: 'power-points-cards',
    iconPath: `${assets}power-points-cards.svg`
  },
  {
    iconName: 'power-points-cards',
    iconPath: `${assets}power-points-cards.svg`
  },
  { iconName: 'queue', iconPath: `${assets}queue.svg` },
  {
    iconName: 'rectangle-vertical-history',
    iconPath: `${assets}rectangle-vertical-history.svg`
  },
  { iconName: 'removeSlot', iconPath: `${assets}remove-slot.svg` },
  { iconName: 'reports', iconPath: `${assets}reports.svg` },
  {
    iconName: 'session-details-table',
    iconPath: `${assets}outbound-session-details-enabled.svg`
  },
  { iconName: 'share', iconPath: `${assets}share.svg` },
  { iconName: 'slider', iconPath: `${assets}sliders.svg` },
  { iconName: 'speaker', iconPath: `${assets}speaker.svg` },
  { iconName: 'switch-chat', iconPath: `${assets}switch-chat.svg` },
  { iconName: 'switch-video', iconPath: `${assets}switch-video.svg` },
  { iconName: 'three-dot-icon', iconPath: `${assets}three-dot.svg` },
  { iconName: 'time-white', iconPath: `${assets}time-white.svg` },
  { iconName: 'tooltip', iconPath: `${assets}tooltip-icon.svg` },
  { iconName: 'video-off-icon', iconPath: `${assets}video-off.svg` },
  { iconName: 'video-on-icon', iconPath: `${assets}video-people.svg` },
  { iconName: 'warning', iconPath: `${assets}warning.svg` },

  //TODO: new set of icons, remove the above icons once migration completes
  { iconName: 'account-setting', iconPath: `${newAssets}account-setting.svg` },
  { iconName: 'alert', iconPath: `${newAssets}alert.svg` },
  { iconName: 'arrow-left', iconPath: `${newAssets}arrow-left.svg` },
  {
    iconName: 'arrow-trend-down',
    iconPath: `${newAssets}arrow-trend-down.svg`
  },
  { iconName: 'arrow-trend-up', iconPath: `${newAssets}arrow-trend-up.svg` },
  { iconName: 'audio-on', iconPath: `${newAssets}audio-on.svg` },
  { iconName: 'badge', iconPath: `${newAssets}badge.svg` },
  { iconName: 'calendar-day', iconPath: `${newAssets}calendar-day.svg` },
  { iconName: 'camera-off', iconPath: `${newAssets}camera-off.svg` },
  { iconName: 'camera-on', iconPath: `${newAssets}camera-on.svg` },
  { iconName: 'camera-switch', iconPath: `${newAssets}camera-switch.svg` },
  { iconName: 'camera', iconPath: `${newAssets}camera.svg` },
  { iconName: 'chat-file', iconPath: `${newAssets}chat-file.svg` },
  { iconName: 'chat-off', iconPath: `${newAssets}chat-off.svg` },
  { iconName: 'chat-on', iconPath: `${newAssets}chat-on.svg` },
  { iconName: 'clock', iconPath: `${newAssets}clock.svg` },
  { iconName: 'circle-plus', iconPath: `${newAssets}circle-plus.svg` },
  { iconName: 'circle-xmark', iconPath: `${newAssets}circle-xmark.svg` },
  { iconName: 'close', iconPath: `${newAssets}close.svg` },
  { iconName: 'coffee-bean', iconPath: `${newAssets}coffee-bean.svg` },
  { iconName: 'coffee-cup', iconPath: `${newAssets}coffee-cup.svg` },
  { iconName: 'co-browse', iconPath: `${newAssets}co-browse.svg` },
  { iconName: 'cursor', iconPath: `${newAssets}cursor.svg` },
  { iconName: 'dashboard', iconPath: `${newAssets}dashboard.svg` },
  { iconName: 'download', iconPath: `${newAssets}download.svg` },
  { iconName: 'draw', iconPath: `${newAssets}drawing.svg` },
  { iconName: 'drawer-arrow', iconPath: `${newAssets}drawer-arrow.svg` },
  { iconName: 'edit', iconPath: `${newAssets}edit.svg` },
  {
    iconName: 'ellipsis-vertical',
    iconPath: `${newAssets}ellipsis-vertical.svg`
  },
  { iconName: 'end-session', iconPath: `${newAssets}end-session.svg` },
  { iconName: 'envelope', iconPath: `${newAssets}envelope.svg` },
  { iconName: 'envelope-jobber', iconPath: `${newAssets}envelope-jobber.svg` },
  { iconName: 'empty-star', iconPath: `${newAssets}empty-star.svg` },
  { iconName: 'fbmessenger', iconPath: `${newAssets}fbmessenger.svg` },
  {
    iconName: 'file',
    iconPath: `${newAssets}/file.svg`
  },
  {
    iconName: 'file-excel',
    iconPath: `${newAssets}/file-excel.svg`
  },
  {
    iconName: 'file-image',
    iconPath: `${newAssets}/file-image.svg`
  },
  {
    iconName: 'file-music',
    iconPath: `${newAssets}/file-music.svg`
  },
  {
    iconName: 'file-pdf',
    iconPath: `${newAssets}/file-pdf.svg`
  },
  {
    iconName: 'file-powerpoint',
    iconPath: `${newAssets}/file-powerpoint.svg`
  },
  {
    iconName: 'file-video',
    iconPath: `${newAssets}/file-video.svg`
  },
  {
    iconName: 'file-word',
    iconPath: `${newAssets}/file-word.svg`
  },
  {
    iconName: 'file-zipper',
    iconPath: `${newAssets}/file-zipper.svg`
  },
  { iconName: 'filter', iconPath: `${newAssets}filter.svg` },
  { iconName: 'globe', iconPath: `${newAssets}globe.svg` },
  { iconName: 'googlebm', iconPath: `${newAssets}googlebm.svg` },
  {
    iconName: 'google-translate',
    iconPath: `${newAssets}google-translate.svg`
  },
  {
    iconName: 'history-down-arrow',
    iconPath: `${newAssets}history-down-arrow.svg`
  },
  { iconName: 'info', iconPath: `${newAssets}info.svg` },
  { iconName: 'invite-icon', iconPath: `${newAssets}invite-icon.svg` },
  { iconName: 'laser', iconPath: `${newAssets}laser.svg` },
  { iconName: 'kb-book', iconPath: `${newAssets}kb-book.svg` },
  { iconName: 'LAM', iconPath: `${newAssets}lam-sms-question.svg` },
  { iconName: 'leads', iconPath: `${newAssets}leads.svg` },
  { iconName: 'left-arrow', iconPath: `${newAssets}left-arrow-swipe.svg` },
  { iconName: 'location-dot', iconPath: `${newAssets}location-dot.svg` },
  {
    iconName: 'location-dot-jobber',
    iconPath: `${newAssets}location-dot-jobber.svg`
  },
  { iconName: 'logout', iconPath: `${newAssets}logout.svg` },
  { iconName: 'message-dots', iconPath: `${newAssets}message-dots.svg` },
  { iconName: 'messages', iconPath: `${newAssets}messages.svg` },
  { iconName: 'minus', iconPath: `${newAssets}minus.svg` },
  { iconName: 'more', iconPath: `${newAssets}menu-bars.svg` },
  { iconName: 'new-queue', iconPath: `${newAssets}new-queue.svg` },
  { iconName: 'new-reports', iconPath: `${newAssets}new-reports.svg` },
  { iconName: 'notes', iconPath: `${newAssets}notes.svg` },
  { iconName: 'observe', iconPath: `${newAssets}observe.svg` },
  { iconName: 'optimy-logo', iconPath: `${newAssets}observe.svg` },
  { iconName: 'outreach', iconPath: `${newAssets}outreach.svg` },
  { iconName: 'overflow-menu', iconPath: `${newAssets}overflow-menu.svg` },
  { iconName: 'paper-clip', iconPath: `${newAssets}paperclip.svg` },
  { iconName: 'participants', iconPath: `${newAssets}participants.svg` },
  { iconName: 'phone', iconPath: `${newAssets}phone.svg` },
  { iconName: 'phone-jobber', iconPath: `${newAssets}phone-jobber.svg` },
  {
    iconName: 'phone-arrow-down-left',
    iconPath: `${newAssets}phone-arrow-down-left.svg`
  },
  {
    iconName: 'phone-arrow-up-right',
    iconPath: `${newAssets}phone-arrow-up-right.svg`
  },
  { iconName: 'phone-hangup', iconPath: `${newAssets}phone-hangup.svg` },
  { iconName: 'phone-missed', iconPath: `${newAssets}phone-missed.svg` },
  { iconName: 'phone-plus', iconPath: `${newAssets}phone-plus.svg` },
  { iconName: 'phone-xmark', iconPath: `${newAssets}phone-xmark.svg` },
  { iconName: 'plus', iconPath: `${newAssets}plus.svg` },
  { iconName: 'reload-warning', iconPath: `${assets}reload-warning.svg` },
  { iconName: 'report-bug', iconPath: `${newAssets}report-bug.svg` },
  { iconName: 'right-arrow', iconPath: `${newAssets}right-arrow-swipe.svg` },
  { iconName: 'schedule', iconPath: `${newAssets}schedule.svg` },
  { iconName: 'sparkles', iconPath: `${newAssets}sparkles.svg` },
  {
    iconName: 'scheduled-calendar',
    iconPath: `${newAssets}scheduled-calendar.svg`
  },
  { iconName: 'screen-share-on', iconPath: `${newAssets}screen-share-on.svg` },
  {
    iconName: 'screen-share-off',
    iconPath: `${newAssets}screen-share-off.svg`
  },
  { iconName: 'search', iconPath: `${newAssets}search.svg` },
  { iconName: 'settings', iconPath: `${newAssets}settings.svg` },
  { iconName: 'sms', iconPath: `${newAssets}sms.svg` },
  { iconName: 'speaker', iconPath: `${newAssets}speaker.svg` },
  { iconName: 'spinner', iconPath: `${newAssets}spinner.svg` },
  { iconName: 'store', iconPath: `${newAssets}store.svg` },
  { iconName: 'teams', iconPath: `${newAssets}teams.svg` },
  { iconName: 'thought-bubble', iconPath: `${newAssets}thought-bubble.svg` },
  { iconName: 'translate', iconPath: `${newAssets}translate.svg` },
  { iconName: 'translate-arrow', iconPath: `${newAssets}translate-arrow.svg` },
  { iconName: 'trash', iconPath: `${newAssets}trash.svg` },
  { iconName: 'up-down-arrow', iconPath: `${newAssets}up-down-arrow.svg` },
  { iconName: 'user', iconPath: `${newAssets}user.svg` },
  { iconName: 'user-jobber', iconPath: `${newAssets}user-jobber.svg` },
  { iconName: 'user-headset', iconPath: `${newAssets}user-headset.svg` },
  { iconName: 'user-offline', iconPath: `${newAssets}user-offline.svg` },
  { iconName: 'user-online', iconPath: `${newAssets}user-online.svg` },
  { iconName: 'user-slash', iconPath: `${newAssets}user-slash.svg` },
  { iconName: 'users', iconPath: `${newAssets}users.svg` },
  { iconName: 'voice-invite', iconPath: `${newAssets}voice-invite.svg` },
  { iconName: 'video-file', iconPath: `${newAssets}video-file.svg` },
  { iconName: 'video-invite', iconPath: `${newAssets}video-invite.svg` },
  { iconName: 'web', iconPath: `${newAssets}web.svg` }
];
