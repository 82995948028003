import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { UserDetails } from '../core/constants/dashboard-modal';
import { DashboardService } from '../core/services/dashboard/dashboard.service';
import * as appActions from './app.actions';
import { UtilsService } from '../core/services/utils/utils.service';
import { MockDataRooms, UserInfo } from '../core/constants/common.enum';
import { Agent } from '../core/constants/video-config';
import { VideoChatService } from '../core/services/video-chat/video-chat.service';
import { SocketService } from '../core/services/socket/socket.service';
import {
  LanguageList,
  QueueCall,
  QueueCalls,
  QueueCallsAndLists
} from '../modules/root/new-queue/modals/queue.config';
import { QueueService } from '../modules/root/new-queue/services/queue-service/queue.service';
import { RootUtilsService } from '../modules/core/services/root-utils/root-utils.service';
import demoCallData from '../../assets/api-mocks/demoCallData.json';
import * as moment from 'moment';
import { environment } from '../../environments/environment';
import { AutoDashboardService } from '../modules/root/auto-dashboard/services/auto-dashboard.service';
import { ReportReadResponse } from '../modules/root/auto-dashboard/modals/auto-dashboard.config';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class AppEffects {
  userInfo: UserInfo;
  mockCallData = this.modifyMockData(demoCallData?.queue_calls);

  constructor(
    private actions$: Actions,
    private utils: UtilsService,
    private dashboardService: DashboardService,
    private socketService: SocketService,
    private videoChatService: VideoChatService,
    private queueService: QueueService,
    private rootUtilsService: RootUtilsService,
    private autoDashboardService: AutoDashboardService,
    private translate: TranslateService
  ) {}

  loadUserDetails$ = createEffect(() =>
    this.actions$.pipe(
      ofType(appActions.LOAD_USER_DETAILS),
      switchMap((loadUserInfoAction: appActions.LoadUserDetailsAction) =>
        this.dashboardService.getUserDetails(loadUserInfoAction.user_id).pipe(
          map((userInfo: UserDetails) => {
            this.userInfo = this.utils.getUserInfo();
            this.userInfo['user_roles'] = userInfo?.roles;
            this.userInfo.default_language = userInfo?.default_language;
            this.utils.setUserInfo(this.userInfo);
            let queueTenantIdInfo = {};
            userInfo?.queue_list?.forEach((queue) => {
              queueTenantIdInfo[queue?.queue_id] = queue?.tenantid;
            });
            this.rootUtilsService.setQueueTenantInfo(queueTenantIdInfo);
            let filterQueueList = [...userInfo?.queue_list];
            if (userInfo?.roles?.includes('SAVE Admin')) {
              filterQueueList = userInfo?.queue_list?.filter(
                (queue) => queue?.access
              );
            }
            const queueList: string[] = filterQueueList?.map(function (el) {
              return `queue${el.queue_id}`;
            });
            this.rootUtilsService.setAllAvailableQueues(queueList);
            this.socketService.joinRoom([
              ...queueList,
              `agent${userInfo.tenant_id}`
            ]);
            if (userInfo?.default_language) {
              this.translate.setDefaultLang(userInfo.default_language);
              this.utils.setLanguage(userInfo.default_language);
            }
            return new appActions.LoadUserDetailsSuccessAction(userInfo);
          }),
          catchError((error) =>
            of(new appActions.LoadUserDetailsFailureAction(error))
          )
        )
      )
    )
  );

  loadQueueList$ = createEffect(() =>
    this.actions$.pipe(
      ofType(appActions.LOAD_QUEUE_CALLS_LIST),
      switchMap(() =>
        this.queueService.getQueueList().pipe(
          map((queueCallsAndLists: QueueCallsAndLists) => {
            this.rootUtilsService.clearAllAvailableRooms();
            const tenantId =
              this.utils.getClient()?.tenant_id ||
              this.utils.getUserInfo()?.tenantid;
            this.removeOldLocalStorageValues(
              queueCallsAndLists?.queue_calls?.in_progress
            );
            if (tenantId === 8) {
              const originalData = queueCallsAndLists.queue_calls;
              const updatedData: any = {
                queue_list: queueCallsAndLists?.queue_list,
                queue_calls: this.modifyQueueListData(
                  this.mockCallData,
                  originalData
                )
              };
              return new appActions.LoadQueueCallsAndListsSuccessAction(
                updatedData
              );
            } else {
              return new appActions.LoadQueueCallsAndListsSuccessAction(
                queueCallsAndLists
              );
            }
          }),
          catchError((error) =>
            of(new appActions.LoadQueueCallsAndListsFailureAction(error))
          )
        )
      )
    )
  );

  loadAgentList$ = createEffect(() =>
    this.actions$.pipe(
      ofType(appActions.LOAD_AGENT_LIST),
      switchMap(() =>
        this.videoChatService.getAgentList().pipe(
          map((agentList: Agent[]) => {
            return new appActions.LoadAgentListSuccessAction(agentList);
          }),
          catchError((error) =>
            of(new appActions.LoadAgentListFailureAction(error))
          )
        )
      )
    )
  );

  loadLanguageList$ = createEffect(() =>
    this.actions$.pipe(
      ofType(appActions.LOAD_LANGUAGE_LIST),
      switchMap(() =>
        this.queueService.getLanguageList().pipe(
          map((languages: LanguageList[]) => {
            return new appActions.LoadLanguageListSuccessAction(languages);
          }),
          catchError((error) =>
            of(new appActions.LoadLanguageListFailureAction(error))
          )
        )
      )
    )
  );

  loadDashboardReport$ = createEffect(() =>
    this.actions$.pipe(
      ofType(appActions.LOAD_DASHBOARD_REPORT),
      switchMap(() =>
        this.autoDashboardService.getReportList().pipe(
          map((reportInfo: ReportReadResponse[]) => {
            return new appActions.LoadDashboardReportSuccessAction(reportInfo);
          }),
          catchError((error) =>
            of(new appActions.LoadDashboardReportFailureAction(error))
          )
        )
      )
    )
  );

  modifyQueueListData(mockData: QueueCall[], originalData: QueueCalls) {
    let modifiedQueueCalls;
    modifiedQueueCalls = {
      scheduled: this.getUpdatedList(
        'Scheduled',
        mockData,
        originalData?.scheduled
      ),
      pending: this.getUpdatedList('Pending', mockData, originalData?.pending),
      in_progress: this.getUpdatedList(
        'In Progress',
        mockData,
        originalData?.in_progress
      )
    };
    return modifiedQueueCalls;
  }

  getUpdatedList(
    type: string,
    mockData: QueueCall[],
    originalData: QueueCall[]
  ) {
    if (type === 'Scheduled') {
      const scheduledMock = mockData?.filter(
        (res) => res?.status === 'Scheduled'
      );
      return originalData?.length
        ? [...originalData, ...scheduledMock]
        : [...scheduledMock];
    } else if (type === 'Pending') {
      const pendingMock = mockData?.filter((res) => res?.status === 'Pending');
      return originalData?.length
        ? [...originalData, ...pendingMock]
        : [...pendingMock];
    } else if (type === 'In Progress') {
      const inProgressMock = mockData?.filter(
        (res) => res?.status === 'In Progress'
      );
      return originalData?.length
        ? [...originalData, ...inProgressMock]
        : [...inProgressMock];
    }
  }

  modifyMockData(mockData: QueueCall[]): QueueCall[] {
    const mockDataRooms = MockDataRooms[environment.name];
    const timeRange = [10, 15, 20, 25, 30];
    let mockPendingIndex = 0;
    return mockData.map((res, index) => {
      if (res?.status === 'Scheduled') {
        res.room_name = mockDataRooms?.scheduled;
        const rndInt = (index + 1) * 5;
        res.created = moment(new Date()).add(rndInt, 'days').unix();
        res.scheduled_datetime = moment(new Date())
          .add(rndInt, 'days')
          .format('YYYY-MM-DDTHH:mm');
      }
      if (res?.status === 'Pending') {
        res.room_name = mockDataRooms?.pending[mockPendingIndex];
        res.created = moment(new Date())
          .subtract(timeRange[mockPendingIndex], 'seconds')
          .unix();
        mockPendingIndex++;
      }
      if (res?.status === 'In Progress') {
        res.room_name = mockDataRooms?.inProgress;
        res.started = moment(new Date()).subtract(5, 'seconds').unix();
      }
      return res;
    });
  }

  removeOldLocalStorageValues(inProgressCalls: QueueCall[]) {
    const keys: string[] = Object.keys(localStorage);
    const numberKeys: number[] = keys
      .filter((key) => !isNaN(Number(key)))
      .map((key) => Number(key));
    const existingKeys = inProgressCalls?.map((res) => res?.queue_position_id);
    const oldKeys = numberKeys?.filter((key) => !existingKeys.includes(key));
    oldKeys.forEach((res) => {
      console.log('removing old key', res);
      localStorage.removeItem(`${res}`);
    });
  }
}
