import { Injectable } from '@angular/core';
import { TokenReq, UserInfo } from '../../constants/common.enum';
import { Constants } from '../../constants/constants';
import { UtilsService } from '../utils/utils.service';
import { Store } from '@ngrx/store';
import * as AppState from '../../../state/app.state';
import * as AppActions from '../../../state/app.actions';
import { SocketService } from '../socket/socket.service';
import { Observable, of } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { catchError, map, take } from 'rxjs/operators';
import { HttpWrapperService } from '../http-service/http-wrapper.service';
import { AngularFireMessaging } from '@angular/fire/compat/messaging';

@Injectable({
  providedIn: 'root'
})
export class AppService {
  regex: RegExp;

  constructor(
    private utils: UtilsService,
    private socketService: SocketService,
    private store: Store<AppState.State>,
    private httpClientWrapper: HttpWrapperService,
    private angularFireMessaging: AngularFireMessaging
  ) {
    this.regex = new RegExp(/^([a-z]+\:\/{2})?([\w-]+\.[\w-]+\.\w+)$/);
  }

  init() {
    return new Promise<void>((resolve, reject) => {
      const userInfo = JSON.parse(
        localStorage.getItem(Constants.userInfo)
      ) as UserInfo;
      if (userInfo?.user_id) {
        this.checkAndAssignToken(userInfo.user_id);
      }
      if (userInfo && userInfo.user_id && userInfo.user_token) {
        this.store.dispatch(
          new AppActions.LoadUserDetailsAction(userInfo.user_id)
        );
        this.store.dispatch(new AppActions.UpdateUserInfoAction(userInfo));
      }
      resolve();
    });
  }

  checkAndAssignToken(userId: number) {
    const deviceToken = this.utils.getToken();
    if (deviceToken) {
      this.assignToken(deviceToken, userId);
    } else {
      if ('Notification' in window) {
        this.angularFireMessaging.requestToken.subscribe(
          (token) => {
            if (token) {
              this.utils.setDeviceToken(token);
              this.assignToken(token, userId);
            }
          },
          (err) => {
            console.error('Unable to get permission to notify.', err);
          }
        );
      }
    }
  }

  assignToken(token: string, userId: number) {
    const tokenReq: TokenReq = {
      user_id: userId,
      token: token,
      attr: JSON.stringify({
        userAgent: navigator?.userAgent,
        os: this.getOS()
      })
    };
    this.utils
      .assignToken(tokenReq)
      .pipe(take(1))
      .pipe(
        catchError((err) => {
          console.error('error in notification', err);
          return of(err);
        })
      )
      .subscribe((response) => {
        console.log('notification enabled', response?.message);
      });
  }

  getOS() {
    const platform = window.navigator.userAgent.toLowerCase();

    if (platform.includes('mac')) return 'Mac OS';
    if (platform.includes('win')) return 'Windows';
    if (platform.includes('linux')) return 'Linux';
    if (/iphone|ipad|ipod/.test(platform)) return 'iOS';
    if (/android/.test(platform)) return 'Android';

    return 'Unknown OS';
  }

  // TODO: Need to have a different way to get client configuration.
  findOutClient() {
    let url = location.host;
    if (!!url.match(this.regex) && url.split('.')[0] !== 'www') {
      url = url.split('.')[0];
      this.utils.setIsTenantCode(false);
      this.setClientWithDomain(url);
    } else {
      this.utils.setIsTenantCode(true);
      this.utils.setClient({});
      this.utils.setAppInfo(Constants.clients.optimy);
    }
  }

  setClientWithDomain(url) {
    const client = this.utils.getClient();
    this.utils.setAppInfo(Constants.clients.optimy);
    if (
      (this.convertToLowerAndRemoveSpace(client?.tenantName) === url ||
        this.convertToLowerAndRemoveSpace(client?.tenantCode) === url) &&
      client?.tenant_id
    ) {
      this.utils.setClient({
        ...client,
        tenantCode: url
      });
      return;
    }
    this.utils.setClient({ tenantCode: url, tenantName: url });
  }

  convertToLowerAndRemoveSpace(value: string) {
    return value?.toLowerCase().replace(/\s/g, '');
  }

  getBuildNumber(): Observable<any> {
    let baseURL = environment.getBuildNumberApi;
    return this.httpClientWrapper
      .postReqForLambda(
        baseURL,
        {
          env: environment.environmentName,
          getBuildNumber: true
        },
        {}
      )
      .pipe(
        map((res: any) => {
          return res?.data;
        })
      )
      .pipe(
        catchError(() => {
          return of(null);
        })
      );
  }
}
