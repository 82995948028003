<div class="in-app-notification-container">
  <div *ngFor="let notification of notifications" class="{{cssClass(notification)}}">
    <div class="notification-message">
      <div class="d-flex justify-content-start align-items-center">
        <div class="notification-icon"> </div>
        <span class="notification-text" [innerHtml]="notification.message" (click)="bodyAction(notification);removeNotification(notification)"></span>
      </div>
      <div class="notification-action-button">
        <button class="btn notification-action" (click)="buttonAction(notification);removeNotification(notification)">
          {{notification.buttonLabel}}
        </button>
        <button class="btn notification-action" *ngIf="notification?.cancelLabel" (click)="onCancelButtonAction(notification);removeNotification(notification)">
          {{notification.cancelLabel}}
        </button>
      </div>
      <div class="d-flex align-items-center">
        <svg-icon name="close" class="pointer" (click)="removeNotification(notification)"></svg-icon>
      </div>
    </div>
  </div>
</div>
