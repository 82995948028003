import { UserDetails } from '../core/constants/dashboard-modal';
import { UserInfo } from '../core/constants/common.enum';
import { Agent, AgentStatusUpdateSocket } from '../core/constants/video-config';
import {
  LanguageList,
  QueueCall,
  QueueCallsAndLists
} from '../modules/root/new-queue/modals/queue.config';
import {
  ReportInformation,
  ReportReadResponse
} from '../modules/root/auto-dashboard/modals/auto-dashboard.config';
import { PayloadAction } from '../store/models/payload-action';
import { OutBoundInfo } from '../core/constants/outbound-config';

export const LOAD_USER_DETAILS = '[User Details] load User Details';
export const LOAD_USER_DETAILS_SUCCESS =
  '[User Details Success] load User Details success';
export const LOAD_USER_DETAILS_FAILURE =
  '[User Details Failure] load User Details failure';

export const UPDATE_USER_INFO = '[User Info] update User Info';
// export const LOAD_USER_INFO_SUCCESS = '[User Info Success] load User Info success';
// export const LOAD_USER_INFO_FAILURE = '[User Info Failure] load User Info failure';

/** Queue List */
export const LOAD_QUEUE_CALLS_LIST = '[Socket][Queue calls list Load]';
export const LOAD_QUEUE_CALLS_LIST_SUCCESS =
  '[Socket][Queue calls list Update Success]';
export const LOAD_QUEUE_CALLS_LIST_FAILURE =
  '[Socket][Queue calls list Update failure]';
export const LOAD_PENDING_CALLS = '[Socket][Load Pending Calls]';
export const UPDATE_PENDING_CALLS = '[Socket][Update Pending Calls]';
export const ADD_PENDING_CALLS_LIST = '[Socket][Update Pending Calls List]';
export const DELETE_PENDING_CALL = '[Socket][Delete Pending Call]';
export const LOAD_IN_PROGRESS_CALLS = '[Socket][Load In progress Calls]';
export const UPDATE_IN_PROGRESS_CALLS = '[Socket][Update In progress Calls]';
export const ADD_IN_PROGRESS_CALLS_LIST =
  '[Socket][Update In progress Calls List]';
export const DELETE_IN_PROGRESS_CALL = '[Socket][Delete In progress Call]';
export const ADD_PENDING_CALLS_TO_QUEUE_LIST =
  '[Socket][Add pending calls to queue list]';
export const DELETE_PENDING_CALLS_TO_QUEUE_LIST =
  '[Socket][Delete pending calls from queue list]';
export const ADD_IN_PROGRESS_CALLS_TO_QUEUE_LIST =
  '[Socket][Add In Progress calls to queue list]';
export const DELETE_IN_PROGRESS_CALLS_TO_QUEUE_LIST =
  '[Socket][Delete In Progress calls from queue list]';
export const UPDATE_CONNECTED_PARTICIPANTS_IN_PROGRESS_CALLS =
  '[Socket][Update connected participants In progress Calls]';
export const LOAD_SCHEDULED_CALLS = '[Socket][Load Scheduled Calls]';
export const UPDATE_SCHEDULED_CALLS = '[Socket][Update Scheduled Calls]';
export const SET_ACTIVE_CALL_ID = '[Socket][Set Active Call Index]';

/** Agent List */
export const LOAD_AGENT_LIST = '[Socket][Agent list Update]';
export const LOAD_AGENT_LIST_SUCCESS = '[Socket][Agent list Update Success]';
export const LOAD_AGENT_LIST_FAILURE = '[Socket][Agent list Update failure]';

export const LOAD_DASHBOARD_REPORT = '[Report Details] load Dashboard Report';
export const LOAD_DASHBOARD_REPORT_SUCCESS =
  '[Report Details Success] load Dashboard Report success';
export const LOAD_DASHBOARD_REPORT_FAILURE =
  '[Report Details Failure] load Dashboard Report failure';

export const SET_DASHBOARD_FILTER_INFO = '[Socket][Set Dashboard Filter Info]';
/**
 * Socket
 */
export const AGENT_STATUS_UPDATE =
  '[Socket][Save Agent status Update] queue updated';

export const LOAD_LANGUAGE_LIST = '[Language List] load Language List';
export const LOAD_LANGUAGE_LIST_SUCCESS =
  '[Language List] load Language List success';
export const LOAD_LANGUAGE_LIST_FAILURE =
  '[Language List] load Language List failure';

export const UPDATE_OUTBOUND_INFO = '[OutBound][Update Outbound Info]';

export class UpdateUserInfoAction {
  readonly type = UPDATE_USER_INFO;
  constructor(public userInfo: UserInfo) {}
}
// export class LoadUserInfoSuccessAction {
//   readonly type = LOAD_USER_INFO_SUCCESS;
//   constructor(public userInfo: UserInfo) { }
// }
// export class LoadUserInfoFailureAction {
//   readonly type = LOAD_USER_INFO_FAILURE;
//   constructor(public error: any) { }
// }
export class LoadUserDetailsAction {
  readonly type = LOAD_USER_DETAILS;
  constructor(public user_id: number) {}
}
export class LoadUserDetailsSuccessAction {
  readonly type = LOAD_USER_DETAILS_SUCCESS;
  constructor(public userDetails: UserDetails) {}
}
export class LoadUserDetailsFailureAction {
  readonly type = LOAD_USER_DETAILS_FAILURE;
  constructor(public error: any) {}
}

/** Queue List Actions */
export class LoadQueueCallsAndListsAction {
  readonly type = LOAD_QUEUE_CALLS_LIST;
  constructor() {}
}
export class LoadQueueCallsAndListsSuccessAction {
  readonly type = LOAD_QUEUE_CALLS_LIST_SUCCESS;
  constructor(public queueCallsAndLists: QueueCallsAndLists) {}
}
export class LoadQueueCallsAndListsFailureAction {
  readonly type = LOAD_QUEUE_CALLS_LIST_FAILURE;
  constructor(public error: any) {}
}

export class LoadPendingCallsAction {
  readonly type = LOAD_PENDING_CALLS;
  constructor(public pendingCalls: QueueCall[]) {}
}

export class UpdatePendingCallsAction {
  readonly type = UPDATE_PENDING_CALLS;
  constructor(
    public key: string[],
    public value: any[],
    public roomName: string
  ) {}
}

export class AddPendingCallsListAction {
  readonly type = ADD_PENDING_CALLS_LIST;
  constructor(public call: QueueCall) {}
}

export class DeletePendingCallAction {
  readonly type = DELETE_PENDING_CALL;
  constructor(public roomName: string) {}
}

export class LoadInProgressCallsAction {
  readonly type = LOAD_IN_PROGRESS_CALLS;
  constructor(public inProgressCalls: QueueCall[]) {}
}

export class UpdateInProgressCallsAction {
  readonly type = UPDATE_IN_PROGRESS_CALLS;
  constructor(
    public key: string[],
    public value: any[],
    public roomName: string
  ) {}
}

export class AddInProgressCallsListAction {
  readonly type = ADD_IN_PROGRESS_CALLS_LIST;
  constructor(public call: QueueCall) {}
}

export class DeleteInProgressCallAction {
  readonly type = DELETE_IN_PROGRESS_CALL;
  constructor(public roomName: string) {}
}

export class UpdateConnectedParticipantForInProgressCallsAction {
  readonly type = UPDATE_CONNECTED_PARTICIPANTS_IN_PROGRESS_CALLS;

  constructor(
    public key: string[],
    public value: {},
    public roomName: string,
    public guest_user_id: number
  ) {}
}

export class LoadScheduledCallsAction {
  readonly type = LOAD_SCHEDULED_CALLS;
  constructor(public scheduledCalls: QueueCall[]) {}
}

export class UpdateScheduledCallsAction {
  readonly type = UPDATE_SCHEDULED_CALLS;
  constructor(
    public key: string[],
    public value: any[],
    public roomName: string
  ) {}
}

export class SetActiveCallIdAction {
  readonly type = SET_ACTIVE_CALL_ID;
  constructor(public queueId: number) {}
}

/** Agent List Actions */
export class LoadAgentListAction {
  readonly type = LOAD_AGENT_LIST;
  constructor() {}
}
export class LoadAgentListSuccessAction {
  readonly type = LOAD_AGENT_LIST_SUCCESS;
  constructor(public agentList: Agent[]) {}
}
export class LoadAgentListFailureAction {
  readonly type = LOAD_AGENT_LIST_FAILURE;
  constructor(public error: any) {}
}

export class AgentStatusUpdateAction {
  readonly type = AGENT_STATUS_UPDATE;
  constructor(public agentStatusUpdate: AgentStatusUpdateSocket) {}
}

/** Dashboard report info and filter actions */

export class LoadDashboardReportAction {
  readonly type = LOAD_DASHBOARD_REPORT;
  constructor() {}
}
export class LoadDashboardReportSuccessAction {
  readonly type = LOAD_DASHBOARD_REPORT_SUCCESS;
  constructor(public dashboardReport: ReportReadResponse[]) {}
}
export class LoadDashboardReportFailureAction {
  readonly type = LOAD_DASHBOARD_REPORT_FAILURE;
  constructor(public error: any) {}
}

export class LoadLanguageListAction {
  readonly type = LOAD_LANGUAGE_LIST;
  constructor() {}
}
export class LoadLanguageListSuccessAction {
  readonly type = LOAD_LANGUAGE_LIST_SUCCESS;
  constructor(public languageList: LanguageList[]) {}
}
export class LoadLanguageListFailureAction {
  readonly type = LOAD_LANGUAGE_LIST_FAILURE;
  constructor(public error: any) {}
}

export class SetDashboardReportFilterInfo {
  readonly type = SET_DASHBOARD_FILTER_INFO;
  constructor(public allData: ReportInformation, public xAxisLabel: string) {}
}

export class UpdateOutboundInfo extends PayloadAction<OutBoundInfo> {
  readonly type = UPDATE_OUTBOUND_INFO;
}

export type Action =
  // Load user info {tenantid, timestamp, user_id, user_token}
  | UpdateUserInfoAction
  // LoadUserInfoSuccessAction |
  // LoadUserInfoFailureAction |
  // Load User body UserBody
  | LoadUserDetailsAction
  | LoadUserDetailsSuccessAction
  | LoadUserDetailsFailureAction
  // ---------Queue list-----------------
  | LoadQueueCallsAndListsAction
  | LoadQueueCallsAndListsSuccessAction
  | LoadQueueCallsAndListsFailureAction
  | LoadPendingCallsAction
  | UpdatePendingCallsAction
  | AddPendingCallsListAction
  | DeletePendingCallAction
  | LoadScheduledCallsAction
  | UpdateScheduledCallsAction
  | LoadInProgressCallsAction
  | UpdateInProgressCallsAction
  | AddInProgressCallsListAction
  | DeleteInProgressCallAction
  | UpdateConnectedParticipantForInProgressCallsAction
  | SetActiveCallIdAction
  // ---------Agent list-----------------
  | LoadAgentListAction
  | LoadAgentListSuccessAction
  | LoadAgentListFailureAction
  // --------Agent Status------------------
  | AgentStatusUpdateAction
  // ---------Dashboard Report-----------------
  | LoadDashboardReportAction
  | LoadDashboardReportSuccessAction
  | LoadDashboardReportFailureAction
  | SetDashboardReportFilterInfo
  // ---------Language List --------
  | LoadLanguageListAction
  | LoadLanguageListSuccessAction
  | LoadLanguageListFailureAction
  | UpdateOutboundInfo;
