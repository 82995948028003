import { ActionReducerMap } from '@ngrx/store';
import { appReducer } from '../../state/app.reducer';
import { AppEffects } from '../../state/app.effects';
import { State } from '../../state/app.state';

export interface AppStore {
  app: State;
}

export const reducers: ActionReducerMap<AppStore> = {
  app: appReducer
};

export const effects = [AppEffects];
