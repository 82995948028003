import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';
import 'moment-timezone';
import { Constants } from '../constants/constants';

@Pipe({
  name: 'dateInUserTimezone'
})
export class DateInUserTimezonePipe implements PipeTransform {
  transform(date: string, estZone: boolean, format?: string): string {
    format = format ? format : 'LT';
    const userTimezone = moment.tz.guess();
    const defaultTimezone = moment.tz(
      date,
      estZone ? Constants.estTimezone : Constants.defaultTimezone
    );
    return defaultTimezone.tz(userTimezone).format(format);
  }
}
