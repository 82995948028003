import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import {
  DeviceStatus,
  FcfsData,
  PermissionError
} from '../../constants/video-config';

@Injectable({
  providedIn: 'root'
})
export class SharedService {
  enableDevices$ = new Subject<DeviceStatus>();
  showPermissionError$ = new Subject<PermissionError>();
  fcfsData: FcfsData;

  constructor() {}

  showPermissionError(permissionError: PermissionError) {
    this.showPermissionError$.next(permissionError);
  }

  enableDevices(deviceStatus: DeviceStatus) {
    this.enableDevices$.next(deviceStatus);
  }

  setFcFsData(fcfsData: FcfsData) {
    this.fcfsData = fcfsData;
  }
}
