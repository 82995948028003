import { UtilsService } from 'src/app/core/services/utils/utils.service';
import { environment } from 'src/environments/environment';
import { Injectable } from '@angular/core';
import LogRocket from 'logrocket';
import { Subject } from 'rxjs';

interface LogRocketIdentity extends IUserTraits {
  userid: number;
  tenantid: number;
}

interface IUserTraits {
  [propName: string]: string | number | boolean;
}

@Injectable({
  providedIn: 'root'
})
export class LoggingService {
  initializeLogging$: Subject<boolean> = new Subject<boolean>();

  constructor(private utils: UtilsService) {
    this.initializeLogging$.subscribe(() => {
      this._initializeLogRocket();
    });
  }

  private _initializeLogRocket(): void {
    const identity = this._getCurrentSessionIdentity();
    LogRocket.init(environment.logRocketProjectIdentification);
    LogRocket.identify(identity.userid.toString(), identity);
  }

  private _getCurrentSessionIdentity(): LogRocketIdentity {
    const clientInfo = this.utils.getClient();
    const userInfo = this.utils.getUserInfo();

    return {
      userid: userInfo.user_id,
      tenantid: clientInfo.tenant_id
    };
  }
}
