import { optimyRoutes } from './optimyRoutes';

export const Clients = {
  mpwr: 'MPWR',
  royfoss: 'Royfoss',
  cellcom: 'Cellcom',
  gentraf: 'Gentraf',
  mccarthy: 'McCarthy',
  demo: 'Demo',
  queensshop: 'Queensshop',
  optimy: 'Optimy',
  thescaffeogroup: 'Thescaffeogroup',
  yangsjewellery: 'Yangsjewellery',
  seekstudio: 'Seekstudio'
};

// Variables to facilitate the changes on different environments
const version = 'v1';
const prefix = `/api/${version}`;
const serverPrefix = '';
// const prefix = `https://devfront-optimy-pwa.com/api/${version}`;
// const serverPrefix = 'https://devfront-optimy-pwa.com';

export const Constants = {
  routes: optimyRoutes,
  imagePth: 'assets/images/',
  apiPaths: {
    attachmentCreate: `${prefix}/attachment/create`,
    loginRequestPin: `${prefix}/login/requestpin`,
    validatePin: `${prefix}/login/validatepin`,
    getUserInfo: `${prefix}/user/read`,
    getShift: `${prefix}/shift/list`,
    updateShift: `${prefix}/timekeeping/update`,
    updateClicker: `${prefix}/report/liveresponse/update`,
    createClicker: `${prefix}/report/liveresponse/create`,
    readClicker: `${prefix}/report/liveresponse/read`,
    getSurveyLink: `${prefix}/response/link`,
    getLeaderboardInfo: `${prefix}/report/leaderboard/read`,
    getTaskList: `${prefix}/task/list`,
    updateTask: `${prefix}/task/update`,
    downloadAttachment: `${prefix}/attachment/read`,
    assignToken: `${prefix}/notification/token/assign`,
    unAssignToken: `${prefix}/notification/token/unassign`,
    getNotification: `${prefix}/notification/list`,
    dismissNotification: `${prefix}/notification/dismiss`,
    createSchedule: `${prefix}/availability/week/create`,
    readSchedule: `${prefix}/availability/week/read`,
    copyPrevWeek: `${prefix}/availability/week/copy`,
    copyPrevDay: `${prefix}/availability/day/copy`,
    updateDate: `${prefix}/availability/day/update`,
    updateWeek: `${prefix}/availability/week/update`,
    submitAvailability: `${prefix}/availability/week/submit`,
    createSlot: `${prefix}/availability/slot/create`,
    updateSlot: `${prefix}/availability/slot/update`,
    deleteSlot: `${prefix}/availability/slot/delete`,
    registrationRead: `${prefix}/learning/registration/read`,
    updateQuestionnaire: `${prefix}/response/update`,
    saveGeoLoc: `${prefix}/report/livelocation/update`,
    refreshToken: `${prefix}/login/refreshtoken`,
    createTeamSchedule: `${prefix}/schedule/create`,
    readTeamSchedule: `${prefix}/schedule/read`,
    permissionRead: `${prefix}/schedule/permissions/read`,
    scheduleShiftCreate: `${prefix}/shift/create`,
    scheduleShiftDelete: `${prefix}/shift/delete`,
    scheduleShiftUpdate: `${prefix}/shift/update`,
    scheduleCopy: `${prefix}/schedule/copy`,
    scheduleUpdate: `${prefix}/schedule/update`,
    getChannelList: `${prefix}/chat/userchannel/list`,
    getChatDetails: `${prefix}/chat/message/list`,
    messageCreate: `${prefix}/chat/message/create`,
    getMembersList: `${prefix}/chat/member/list`,
    channelCreate: `${prefix}/chat/channel/create`,
    memberCreate: `${prefix}/chat/member/create`,
    editUnsubmitAvailability: `${prefix}/availability/week/unsubmit`,
    getToken: `${serverPrefix}/token`,
    getCoBrowseToken: `${serverPrefix}/coBrowseToken`,
    readQueueList: `${prefix}/chat/save/queue/read`,
    getQueueList: `${prefix}/chat/save/queue/list`,
    passRoomInfo: `${serverPrefix}/roomInfo`,
    passVideoRoomInfo: `${serverPrefix}/roomCreated`,
    passRoomUpdate: `${serverPrefix}/roomUpdate`,
    getAgentList: `${prefix}/chat/save/agent/list`,
    getRoomInfo: `${prefix}/chat/save/invite/read`,
    getInviteLink: `${prefix}/chat/save/invite/create`,
    getSurveyList: `${prefix}/survey/read`,
    readShift: `${prefix}/shift/read`,
    submitSurveyData: `${prefix}/response/update`,
    queueUpdate: `${prefix}/chat/save/queue/position/update`,
    feedbackCreate: `${prefix}/feedback/create`,
    getUserList: `${prefix}/user/list`,
    pingUser: `${prefix}/ping/user`,
    createCellSchedule: `${prefix}/chat/save/schedule/create`,
    updateCellSchedule: `${prefix}/chat/save/schedule/update`,
    modifyCellScheduleLink: `${prefix}/chat/save/schedule/modifylink`,
    passAssignAgent: `${serverPrefix}/assignAgent`,
    requestAppointmentRoyfoss: `${prefix}/email/send`,
    createRoom: `${serverPrefix}/createRoom`,
    createRecording: `${serverPrefix}/createRecording`,
    getRecordUrl: `${serverPrefix}/getRecordUrl`,
    getMembers: `${serverPrefix}/getMembers`,
    videoEnd: `${serverPrefix}/videoEnd`,
    videoStatus: `${serverPrefix}/videoStatus`,
    getCallHistory: `${prefix}/chat/save/queue/position/list`,
    readReports: `${prefix}/chat/save/report/read`,
    getChatMessages: `${serverPrefix}/retrieveMessageNew`,
    retrieveMessagePagination: `${serverPrefix}/retrieveMessagePagination`,
    createMessage: `${serverPrefix}/createMessage`,
    updateMessage: `${serverPrefix}/updateMessage`,
    typing: `${serverPrefix}/typing`,
    addMembers: `${serverPrefix}/addMembers`,
    updateMember: `${serverPrefix}/updateMember`,
    updateAndDeleteMember: `${serverPrefix}/updateAndDeleteMember`,
    memberUpdated: `${serverPrefix}/memberUpdated`,
    roomClosed: `${serverPrefix}/roomClosed`,
    getParticipantList: `${serverPrefix}/getParticipantList`,
    getUnconsumedMessageIndex: `${serverPrefix}/getUnconsumedMessageIndex`,
    updateMemberChatIndex: `${serverPrefix}/updateMemberChatIndex`,
    getConfigInfo: `${prefix}/config/read`,
    configUpdate: `${prefix}/config/booking/update`,
    getOutboundInfo: `${prefix}/chat/save/outbound/list`,
    outboundInitiated: `${serverPrefix}/outboundInitiated`,
    rewardList: `${prefix}/rewards/list`,
    cobrowseSocket: `${serverPrefix}/cobrowseUpdate`,
    getConversationList: `${prefix}/chat/save/omnichannel/conversation/list`,
    readConversation: `${prefix}/chat/save/omnichannel/conversation/read`,
    sendEmail: `${prefix}/chat/save/omnichannel/agent/send`,
    agentStatusUpdate: `${prefix}/chat/save/agent/status/update`,
    notificationConfigUpdate: `${prefix}/config/notification/update`,
    createChannel: `${serverPrefix}/createChannel`,
    updateLastConsumedMessageIndex: `${serverPrefix}/updateLastConsumedMessageIndex`,
    getEmailLeads: `${serverPrefix}/api/v1/email/lead/options`,
    sendAdfLead: `${serverPrefix}/api/v1/email/lead/send`,
    tenantRead: `${prefix}/tenant/tenantcode/read`,
    guestAgentUpdate: `${prefix}/chat/guest/agent/update`,
    addAndCheckAgentJoin: `${serverPrefix}/addAndCheckAgentJoin`,
    reportBug: `${serverPrefix}/reportBug`,
    dealerCodeRead: `${prefix}/dealer/read`,
    createGuest: `${prefix}/chat/guest/create`,
    addUserToQueue: `${prefix}/chat/save/queue/position/add`,
    pingGuest: `${prefix}/ping/guest`,
    readReport: `${prefix}/report/read`,
    getChatToken: `${serverPrefix}/chatToken`,
    kbSearch: `${serverPrefix}/api/v1/chat/save/kbsearch`,
    getLanguages: `${serverPrefix}/getLanguages`,
    translateText: `${serverPrefix}/translateText`,
    jobberSearch: `${prefix}/jobber/search`,
    jobberClientCreate: `${prefix}/jobber/client/create`,
    jobberClientRead: `${prefix}/jobber/client/read`,
    jobberRequestCreate: `${prefix}/jobber/request/create`,
    declineInvite: `${prefix}/chat/save/invite/decline`
  },
  logoPath: 'assets/images/icons/logo.svg',
  saveLogo: 'assets/images/icons/save-logo.svg',
  cellComLogo: 'assets/images/cellCom-logo.png',
  royFossLogo: 'assets/images/royFoss-logo.png',
  queenShopLogo: 'assets/images/queen-shop-logo.png',
  optimyLogo: 'assets/images/op-logo.png',
  tenantid: 1,
  royFossTenantId: 4,
  cellComTenantId: 5,
  gentrafTenantId: 6,
  demoTenantId: 8,
  queensshopTenantId: 12,
  yangsjewelleryTenantId: 13,
  optimyTenantId: 11,
  thescaffeogroupTenantId: 14,
  seekstudioTenantId: 15,
  cellComQueueId: 5,
  cellcomQueue: 3,
  royfossQueue: 2,
  mpwrQueue: 1,
  mccarthyTenantId: 7,
  mccarthyQueue: 46,
  gentrafQueue: 45,
  demoQueue: 47,
  queensshopQueue: 50,
  yangsjewelleryQueue: 51,
  optimyQueue: 49,
  thescaffeogroupQueue: 52,
  seekstudioQueue: 53,
  cellComSessionDuration: 15,
  userInfo: 'userInfo',
  deviceToken: 'deviceToken',
  lastUpdatedTime: 'lastUpdatedTime',
  startTime: '00:00:00',
  endTime: '23:59:59',
  countDownTime: 60,
  callCountDownTime: 15,
  debounceTime: 10,
  maxWeeklyHour: 60,
  maxDailyHour: 24,
  scheduleSubmittedCode: 1,
  task: 'task',
  notification: 'notification',
  showQuestionnaire: true,
  outOfArea: 'Out-of-area',
  locationError:
    'You are out-of-area. Please make sure you are within area before trying again',
  locationNotSharedError: 'Your device is not sharing location with the app',
  phoneRegex: '^\\(?([0-9]{3})\\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$',
  emailRegex:
    "^((([!#$%&'*+\\-/=?^_`{|}~\\w])|([!#$%&'*+\\-/=?^_`{|}~\\w][!#$%&'*+\\-/=?^_`{|}~\\.\\w]{0,}[!#$%&'*+\\-/=?^_`{|}~\\w]))[@]\\w+([-.]\\w+)*\\.\\w+([-.]\\w+)*)$",
  candadPostalCodeRegex:
    '^([ABCEGHJ-NPRSTVXY][0-9][ABCEGHJ-NPRSTV-Z][ ]?[0-9][ABCEGHJ-NPRSTV-Z][0-9])$',
  vinRegex: '^((?=.*[0-9])(?=.*[A-z])[0-9A-z-]{17})$',
  mpwrAgent: 'mpwrAgent',
  googleMapLink: 'https://maps.google.com/maps?daddr=',
  guestInfo: 'guestInfo',
  roomStatus: 'roomStatus',
  inMainRoom: 'inMainRoom',
  saveAgentFeedback: 'Save Agent Feedback',
  save: 'save',
  star: 'star',
  ratingMaxVal: '5',
  client: 'client',
  loginInfo: 'loginInfo',
  clients: Clients,
  postalCodeRegex: '^([A-Za-z]\\d[A-Za-z][ ]?\\d[A-Za-z]\\d)',
  userNameRegex: '^[A-Za-zàâäèéêëîïôœùûüÿçÀÂÄÈÉÊËÎÏÔŒÙÛÜŸÇ\\s-]+$',
  cellPhoneRegex: '^\\d{10}$',
  getIPAddress: 'https://api.ipify.org/?format=json',
  googleCalendar:
    'https://www.google.com/calendar/render?action=TEMPLATE&text=',
  googleGeoCoding: 'https://maps.googleapis.com/maps/api/geocode/json?',
  findCharRegex: /.{1,3}/g,
  defaultIP: '0.0.0.0',
  defaultDate: '1900-01-01 00:00:00',
  defaultTimezone: 'UTC',
  estTimezone: 'America/Toronto',
  royfossUrl: 'https://www.royfossthornhill.com/',
  RequireSurveyVenueId: 8250,
  cellComPhoneRegex: '^[\\w\\W]{14}$',
  version: 'v02.04',
  smallRoomString: '-agents',
  CoachingMotivationVenueId: 69713,
  incrementRegex: '^[1-9][0-9]?$|^99$',
  mediaDevices: 'mediaDevices',
  locationRegex: '^[a-zA-Z ]*$',
  ipfyUrl: 'https://api.ipify.org/?format=json',
  eChatSessionStorage: 'eChatSessionStorage',
  noTenantCode: 'noTenantCode'
};
