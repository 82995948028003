import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { Constants } from '../../../../core/constants/constants';
import { catchError, map } from 'rxjs/operators';
import { HttpWrapperService } from '../../../../core/services/http-service/http-wrapper.service';
import { ReportReadResponse } from '../modals/auto-dashboard.config';

@Injectable({
  providedIn: 'root'
})
export class AutoDashboardService {
  constructor(private httpClientWrapper: HttpWrapperService) {}

  getReportList(): Observable<ReportReadResponse[] | any> {
    return this.httpClientWrapper
      .post('sample', `${Constants.apiPaths.readReport}`, {})
      .pipe(
        map((res: any) => {
          return res.data;
        })
      )
      .pipe(
        catchError((e) => {
          return throwError(e);
        })
      );
  }
}
