import { Action } from '@ngrx/store';

export abstract class BaseAction implements Action {

  public abstract readonly type: string;

  protected constructor() {
  }

}

