import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import io from 'socket.io-client';
import { environment } from '../../../../environments/environment';
import * as appActions from '../../../state/app.actions';
import * as AppState from '../../../state/app.state';
import {
  ConversationWebHookMessage,
  TypingInfo
} from '../../constants/chat-config';
import { RecordingSessionInfo } from '../../constants/cobrowse-config';
import { OutboundSocket } from '../../constants/outbound-config';
import {
  AgentRemoved,
  AgentStatusUpdateSocket,
  GuestStatusUpdate,
  MemberUpdatedSocket,
  QueueUpdateRes,
  RoomEndSocket
} from '../../constants/video-config';
import { UtilsService } from '../utils/utils.service';
import {
  MsgRecommendationData,
  RoomClose,
  RoomCreatedData
} from '../../../modules/root/new-queue/modules/call/modals/call-config';
import {
  GuestAgentUpdateReq,
  QueueCall
} from '../../../modules/root/new-queue/modals/queue.config';
import { RootUtilsService } from '../../../modules/core/services/root-utils/root-utils.service';
import { RoomInfoData } from '../../../modules/external-chat-outlet/components/modal/e-chal-modal';
import { BuildUpdate } from '../../constants/common.enum';
import { Composition } from '../../../modules/root/history/models';
import { LiveSaleData } from '../../../modules/root/auto-dashboard/modals/auto-dashboard.config';

const socket = io(environment.baseClientUrl, { transports: ['websocket'] });

@Injectable({
  providedIn: 'root'
})
export class SocketService {
  public messageData = new Subject<ConversationWebHookMessage>();
  public memberUpdatedSocket = new Subject<MemberUpdatedSocket>();
  public roomClosed = new Subject<RoomClose>();
  public roomCreated = new Subject<RoomCreatedData>();
  public outboundAccepted = new Subject<RoomEndSocket>();
  public outboundRejected = new Subject<RoomEndSocket>();
  public guestStatusUpdate = new Subject<GuestStatusUpdate>();
  public activeOutreach = new Subject<OutboundSocket>();
  public newSessionDetected = new Subject<RecordingSessionInfo>();
  public startRecordInteractionAction = new Subject<any>();
  public stopRecordInteractionAction = new Subject<any>();
  public queueUpdate = new Subject<QueueUpdateRes>();
  public newQueueUpdate = new Subject<QueueCall>();
  public typingInfo = new Subject<TypingInfo>();
  public agentRemoved = new Subject<AgentRemoved>();
  public compositionUpdated$ = new Subject<Composition>();
  public guestUpdateSocket = new Subject<GuestAgentUpdateReq>();
  public messageUpdateFromGpt$ = new Subject<MsgRecommendationData>();
  public messageFromKnowledgeBase$ = new Subject<MsgRecommendationData>();
  public roomData = new Subject<RoomInfoData>();
  public buildUpdate$ = new Subject<BuildUpdate>();
  public salesDashboardUpdate$ = new Subject<LiveSaleData[]>();

  constructor(
    private appStore: Store<AppState.State>,
    private rootUtilsService: RootUtilsService,
    private utils: UtilsService
  ) {
    socket.on('connect', () => {
      this.reJoinAllRooms();
    });

    socket.on('agentStatusUpdate', (res: AgentStatusUpdateSocket) => {
      this.appStore.dispatch(new appActions.AgentStatusUpdateAction(res));
      this.appStore.dispatch(new appActions.LoadAgentListAction());
      this.consoleMessage('agentStatusUpdate', res);
    });

    socket.on('compositionStatus', (res: Composition) => {
      this.consoleMessage('composition Status', res);
      this.compositionUpdated$.next(res);
    });

    socket.on('guestStatus', (res: GuestStatusUpdate) => {
      this.guestStatusUpdate.next(res);
      this.consoleMessage('guestStatus', res);
    });

    socket.on('guestUpdate', (res: GuestAgentUpdateReq) => {
      this.consoleMessage('guestUpdate', res);
      this.guestUpdateSocket.next(res);
    });

    socket.on('gptRecommendation', (res: MsgRecommendationData) => {
      this.messageUpdateFromGpt$.next(res);
      this.consoleMessage('gptRecommendation', res);
    });

    socket.on('knowledgeBaseRecommendation', (res: MsgRecommendationData) => {
      this.messageFromKnowledgeBase$.next(res);
      this.consoleMessage('knowledgeBaseRecommendation', res);
    });

    socket.on('memberUpdated', (res: MemberUpdatedSocket) => {
      this.memberUpdatedSocket.next(res);
      this.consoleMessage('memberUpdated', res);
    });

    socket.on('outboundAccepted', (res: RoomEndSocket) => {
      this.outboundAccepted.next(res);
      this.consoleMessage('outboundAccepted', res);
    });

    socket.on('outboundNotifications', (res: OutboundSocket) => {
      this.consoleMessage('outboundNotifications', res);
      this.activeOutreach.next(res);
    });

    socket.on('outboundRejected', (res: RoomEndSocket) => {
      this.outboundRejected.next(res);
      this.consoleMessage('outboundRejected', res);
    });

    socket.on('roomClosed', (res: RoomClose) => {
      this.roomClosed.next(res);
      this.consoleMessage('roomClosed', res);
    });

    socket.on('roomCreated', (res: RoomCreatedData) => {
      this.roomCreated.next(res);
      this.consoleMessage('roomCreated', res);
    });

    socket.on('sms', (res: ConversationWebHookMessage) => {
      this.messageData.next(res);
      this.consoleMessage('message', res);
    });

    socket.on('typing', (res: TypingInfo) => {
      this.consoleMessage('typing', res);
      this.typingInfo.next(res);
    });

    socket.on('agentRemoved', (res: AgentRemoved) => {
      this.agentRemoved.next(res);
      this.consoleMessage('agentRemoved', res);
    });
    // socket.on('updateQueue', (res: QueueUpdateRes) => {
    //   this.queueUpdate.next(res);
    //   this.consoleMessage('updateQueue', res);
    // });

    socket.on('newQueueUpdate', (res: QueueCall) => {
      this.newQueueUpdate.next(res);
      this.consoleMessage('updateQueue1', res);
    });

    //TODO: Deprecated as per socket doc
    socket.on('newSessionDetected', (res: RecordingSessionInfo) => {
      this.newSessionDetected.next(res);
    });
    socket.on('startRecordInteraction', (res: RecordingSessionInfo) => {
      this.consoleMessage('startRecordInteraction', res);
      this.startRecordInteractionAction.next(res);
    });
    socket.on('stopRecordInteraction', (res: RecordingSessionInfo) => {
      this.consoleMessage('stopRecordInteraction', res);
      this.stopRecordInteractionAction.next(res);
    });

    socket.on('roomInfo', (res: RoomInfoData) => {
      this.consoleMessage('roomInfo', res);
      this.roomData.next(res);
    });

    socket.on('buildUpdate', (res: BuildUpdate) => {
      this.consoleMessage('buildUpdate', res);
      this.buildUpdate$.next(res);
    });

    socket.on('salesDashboardUpdate', (res: LiveSaleData[]) => {
      this.consoleMessage('salesDashboardUpdate', res);
      this.salesDashboardUpdate$.next(res);
    });
  }

  consoleMessage(message: string, res: any): void {
    if (!environment.production) {
      console.warn(message, res);
    }
  }

  reJoinAllRooms() {
    const tenantId =
      this.utils.getClient()?.tenant_id || this.utils.getUserInfo()?.tenantid;
    const roomName: string[] = this.rootUtilsService.getAllAvailableRooms();
    const queueList: string[] = this.rootUtilsService.getAllAvailableQueues();
    const rooms = roomName?.length
      ? [`agent${tenantId}`, ...roomName, ...queueList]
      : [`agent${tenantId}`, ...queueList];
    this.joinRoom(rooms);
  }

  joinRoom(room: string | string[]) {
    socket.emit('join_room', room, () => {});
  }
}
