import { Injectable } from '@angular/core';
import { OutboundData } from '../../../../core/constants/outbound-config';
import * as moment from 'moment';
import { Participant } from '../../../root/new-queue/modules/call/modals/call-config';
import {
  AgentNotificationData,
  DeviceTypes,
  GeneralQueueData,
  QueueCall
} from '../../../root/new-queue/modals/queue.config';
import { Subject } from 'rxjs';
import { QueueService } from '../../../root/new-queue/services/queue-service/queue.service';

@Injectable({
  providedIn: 'root'
})
export class RootUtilsService {
  activeCallInfos = 'activeCallInfos';
  generalQueueData = 'generalQueueData';
  sideNavPosition = 'sideNavPosition';
  invitedQueueId = 'invitedQueueId';
  roomAvailableList = 'roomAvailableList';
  queueAvailableList = 'queueAvailableList';
  queueTenantIds = 'queueTenantIds';
  selectedScheduledCallInfo = 'selectedScheduledCallInfo';

  messageSentAudioPlayed = false;
  messageSentAudio = new Audio('/assets/audio/message-sent-audio.mp3');

  public inAppNotificationStartCall$ = new Subject<any>();
  public inAppNotification$ = new Subject<any>();
  public showFlashForRescueCall$ = new Subject<any>();

  constructor(private queueService: QueueService) {}

  setNavPosition(position: string) {
    sessionStorage.setItem(this.sideNavPosition, position);
  }

  getNavPosition(): string {
    return sessionStorage.getItem(this.sideNavPosition);
  }

  setLocalStorageInfos(isGeneral: boolean, keys: string[], values: any) {
    const storeKey = isGeneral ? this.generalQueueData : this.activeCallInfos;
    const activeCallState =
      JSON.parse(<string>localStorage.getItem(storeKey)) || {};
    keys.forEach(function (key, index) {
      activeCallState[key] = values[index];
    });
    localStorage.setItem(storeKey, JSON.stringify(activeCallState));
  }

  removeLocalStorageInfos(isGeneral: boolean, key: string) {
    const storeKey = isGeneral ? this.generalQueueData : this.activeCallInfos;
    localStorage.removeItem(storeKey[key]);
  }

  setQueueTenantInfo(value) {
    return localStorage.setItem(this.queueTenantIds, JSON.stringify(value));
  }

  getQueueTenantInfo() {
    return JSON.parse(<string>localStorage.getItem(this.queueTenantIds));
  }

  // setInvitedQueueId(queueId: number) {
  //   sessionStorage.setItem(this.invitedQueueId, JSON.stringify(queueId));
  // }

  // clearInvitedQueueId() {
  //   sessionStorage.removeItem(this.invitedQueueId);
  // }

  // getInvitedQueueId(): number {
  //   return JSON.parse(sessionStorage.getItem(this.invitedQueueId));
  // }

  getLocalStorageInfos(isGeneral: boolean, getAll: boolean, key?: string): any {
    const storeKey = isGeneral ? this.generalQueueData : this.activeCallInfos;
    const activeCallState = JSON.parse(<string>localStorage.getItem(storeKey));
    return activeCallState
      ? getAll
        ? activeCallState
        : activeCallState[key]
      : null;
  }

  setCallSpecificInfoInLocal(queueId: number, keys: string[], values: any) {
    const storeKey = queueId?.toString();
    const activeCallState =
      JSON.parse(<string>localStorage.getItem(storeKey)) || {};
    keys.forEach(function (key, index) {
      activeCallState[key] = values[index];
    });
    localStorage.setItem(storeKey, JSON.stringify(activeCallState));
  }

  getCallSpecificInfoInLocal(
    queueId: number,
    getAll: boolean,
    key?: string
  ): any {
    const storeKey = queueId?.toString();
    const activeCallState = JSON.parse(<string>localStorage.getItem(storeKey));
    return activeCallState
      ? getAll
        ? activeCallState
        : activeCallState[key]
      : null;
  }

  removeCallSpecificInfoInLocal(queueId: number) {
    if (queueId) {
      const storeKey = queueId.toString();
      localStorage.removeItem(storeKey);
    }
  }

  setPageHistoryDifference(element: OutboundData, isFormHistory?: boolean) {
    const history = element?.page_history;
    const historyElement = [];
    for (let i = 0; i < history?.length; i++) {
      const format = 'DD/MM/YYYY LTS';
      const newHistory = { ...history[i] };
      const firstHistoryTime = moment
        .unix(history[i]?.page_load_timestamp)
        .format(format);
      let secondHistoryTime;
      if (history?.length === i + 1) {
        secondHistoryTime = moment.unix(element?.last_seen).format(format);
      } else {
        secondHistoryTime = moment
          .unix(history[i + 1]?.page_load_timestamp)
          .format(format);
      }
      const ms = moment(secondHistoryTime, format).diff(
        moment(firstHistoryTime, format)
      );
      const diff = moment.duration(ms);
      const minutes = Math.floor(diff.asMinutes());
      const seconds = moment.utc(ms).locale('en').format('s');
      newHistory.timeDiff =
        minutes === 0
          ? `${seconds}${isFormHistory ? 's' : ' secs'}`
          : minutes > 0
          ? `${minutes}${isFormHistory ? 'm' : ' mins'} ${
              isFormHistory ? '' : ':'
            } ${seconds}${isFormHistory ? 's' : ' secs'}`
          : `${seconds}${isFormHistory ? 's' : ' secs'}`;
      if (isFormHistory) {
        newHistory.page_site_url = new URL(element?.session_page_url)?.origin;
      }
      historyElement.push(newHistory);
    }
    return historyElement;
  }

  getCurrentTime(time: number, inSeconds?: boolean) {
    const momentDiff = this.getTimeDiff(time);
    const diff = moment.duration(momentDiff);
    return inSeconds
      ? Math.floor(diff.asSeconds())
      : `${Math.floor(diff.asHours())}${moment
          .utc(momentDiff)
          .locale('en')
          .format(':mm:ss')}`;
  }

  getTimeDiff(time: number) {
    const format = 'DD/MM/YYYY LTS';
    const currentTime = moment(new Date()).format(format);
    const sessionTime = moment.unix(time).format(format);
    return moment(currentTime, format).diff(moment(sessionTime, format));
  }

  setAllAvailableRooms(roomList: string[]) {
    let roomAvailableList =
      JSON.parse(<string>localStorage.getItem(this.roomAvailableList)) || [];
    roomAvailableList = [...new Set([...roomAvailableList, ...roomList])];
    localStorage.setItem(
      this.roomAvailableList,
      JSON.stringify(roomAvailableList)
    );
  }

  getAllAvailableRooms(): string[] {
    return (
      JSON.parse(<string>localStorage.getItem(this.roomAvailableList)) || []
    );
  }

  clearAllAvailableRooms() {
    localStorage.removeItem(this.roomAvailableList);
  }

  setAllAvailableQueues(queueList: string[]) {
    const queueAvailableList = [...new Set([...queueList])];
    localStorage.setItem(
      this.queueAvailableList,
      JSON.stringify(queueAvailableList)
    );
  }

  getAllAvailableQueues(): string[] {
    return (
      JSON.parse(<string>localStorage.getItem(this.queueAvailableList)) || []
    );
  }

  getAgentInfo(participant: Participant) {
    return (
      (participant?.identity && !participant?.identity?.includes('guest_')) ||
      (!participant?.identity && !participant?.messagingBinding?.type)
    );
  }

  getFirstGuestFormParticipants(call: QueueCall) {
    if (call?.participantList) {
      const guest = call?.participantList?.find(
        (participant) =>
          participant?.identity?.includes('guest_') &&
          !participant?.attributes?.disconnected_timestamp &&
          !participant?.messagingBinding?.address
      );
      if (guest?.identity && guest?.identity !== 'guest_0') {
        return { [guest.identity]: true };
      } else if (guest?.attributes?.domain_userid) {
        return { [guest.attributes.domain_userid]: true };
      }
      return {};
    }
    return { [call?.guest_user_id]: true };
  }

  //TODO: Keeping the function for future use

  sortInProgressCallsByMessageTime(calls) {
    let inProgressCalls = [...calls];
    let guestMsgCalls = [];
    let agentMsgCalls = [];
    let otherCalls = [];
    inProgressCalls.forEach((call) => {
      const callMsgList = call?.messageList?.length
        ? [...call?.messageList]
        : [];
      const agentIndex = this.findLatestAgentMsgFromList(callMsgList);
      const guestArray = callMsgList?.slice(
        agentIndex >= 0 ? agentIndex + 1 : 0
      );
      const firstGuest = guestArray?.length
        ? guestArray?.find(
            (msg) =>
              (msg?.author?.includes('+') ? true : isNaN(+msg?.author)) &&
              !msg?.author?.includes('system') &&
              !msg?.author?.includes('concierge')
          )
        : '';
      if (firstGuest) {
        guestMsgCalls.push({
          ...call,
          latestMsgTime: firstGuest?.dateCreated
        });
      } else {
        if (
          !callMsgList?.[agentIndex]?.author?.includes('+') &&
          !isNaN(+callMsgList?.[agentIndex]?.author)
        ) {
          agentMsgCalls.push({
            ...call,
            latestMsgTime: callMsgList?.[agentIndex]?.dateCreated
          });
        } else {
          otherCalls.push(call);
        }
      }
    });
    return [
      ...this.getSortedCalls(guestMsgCalls),
      ...this.getSortedCalls(agentMsgCalls),
      ...otherCalls
    ];
  }

  getSortedCalls(calls) {
    return calls?.sort((a, b) =>
      moment(a?.latestMsgTime).diff(moment(b?.latestMsgTime))
    );
  }

  findLatestAgentMsgFromList(msgList) {
    let index;
    for (let i = msgList.length - 1; i >= 0; i--) {
      if (!msgList[i]?.author?.includes('+') && !isNaN(+msgList[i]?.author)) {
        index = i;
        break;
      }
    }
    return index;
  }

  getAndSetAgentFilterData(userDetails) {
    const filtersInfo = this.getLocalStorageInfos(
      true,
      false,
      GeneralQueueData.filterData
    );
    if (
      filtersInfo &&
      filtersInfo?.agentFilter?.length &&
      !filtersInfo?.agentFilter?.includes(userDetails?.user_id)
    ) {
      this.queueService.setAgentFilterValue$.next([
        ...filtersInfo?.agentFilter,
        userDetails?.user_id
      ]);
      const newFilterData = {
        queueFilter: filtersInfo?.queueFilter?.length
          ? [...filtersInfo?.queueFilter]
          : [],
        agentFilter: [...filtersInfo?.agentFilter, userDetails?.user_id]
      };
      this.setLocalStorageInfos(
        true,
        [GeneralQueueData.filterData],
        [newFilterData]
      );
    } else if (!filtersInfo?.agentFilter?.length) {
      this.queueService.setAgentFilterValue$.next([0, userDetails?.user_id]);
      const newFilterData = {
        queueFilter: filtersInfo?.queueFilter?.length
          ? [...filtersInfo?.queueFilter]
          : [],
        agentFilter: [0, userDetails?.user_id]
      };
      this.setLocalStorageInfos(
        true,
        [GeneralQueueData.filterData],
        [newFilterData]
      );
    }
  }

  playMessageSentAudio() {
    const notificationConfig = this.getLocalStorageInfos(
      true,
      false,
      AgentNotificationData.notificationConfig
    );
    if (
      notificationConfig?.message_sent?.notification_type?.in_app_sound &&
      !this.messageSentAudioPlayed
    ) {
      this.messageSentAudio
        .play()
        .then(() => {})
        .catch(() => {});
      this.messageSentAudioPlayed = true;
      setTimeout(() => {
        this.messageSentAudioPlayed = false;
      }, 2500);
    }
  }

  setSelectedScheduledCallInfo(value) {
    return localStorage.setItem(
      this.selectedScheduledCallInfo,
      JSON.stringify(value)
    );
  }

  getSelectedScheduledCallInfo() {
    return JSON.parse(
      <string>localStorage.getItem(this.selectedScheduledCallInfo)
    );
  }

  setValuesToSessionStorage(value, key) {
    return sessionStorage.setItem(key, JSON.stringify(value));
  }

  getValuesFromSessionStorage(key) {
    return JSON.parse(<string>sessionStorage.getItem(key));
  }

  removeValuesFromSessionStorage(channelSid, key) {
    const messages = this.getValuesFromSessionStorage(key);
    delete messages?.[channelSid];
    this.setValuesToSessionStorage(messages, key);
  }

  getMobileOperatingSystem(devices: any[]) {
    const userAgent = navigator.userAgent;
    const latestDevices = devices?.filter(
      (device) => device?.kind === DeviceTypes.videoInput
    );
    if (
      userAgent.match(/iPad/i) ||
      userAgent.match(/iPhone/i) ||
      userAgent.match(/iPod/i)
    ) {
      return this.getIosVideoDevices(latestDevices);
    } else if (userAgent.match(/Android/i)) {
      return this.getAndroidVideoDevices(latestDevices);
    } else {
      return latestDevices?.length ? latestDevices : [];
    }
  }

  isOnMobile(): boolean {
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      navigator.userAgent
    );
  }

  getIosVideoDevices(devices) {
    return devices?.length > 2
      ? [...devices?.splice(0, 2)]
      : devices?.length
      ? [...devices]
      : [];
  }

  getAndroidVideoDevices(devices) {
    const front = devices?.find((device) => device?.label?.includes('front'));
    const back = devices?.find((device) => device?.label?.includes('back'));
    let androidDevices = [];
    const deviceList =
      front && back
        ? androidDevices.push(front, back)
        : front
        ? androidDevices.push(front)
        : back
        ? androidDevices.push(back)
        : {};
    return androidDevices;
  }

  async checkAndGetData(rescue) {
    const currentDay = rescue?.hours?.[moment().format('dddd').toLowerCase()];
    if (this.checkIsSameTimeZone(rescue?.timezone)) {
      const timeMaching = this.checkTimeIsMatching(currentDay);
      return timeMaching;
    } else {
      const convertedCurrentDay = await this.convertTimeToUserTimezone(
        currentDay,
        rescue?.timezone
      );
      return this.checkTimeIsMatching(convertedCurrentDay);
    }
  }

  checkTimeIsMatching(dayHours) {
    const format = 'HH:mm';
    const currentTime = moment().format('HH:mm');
    let isBetweenTime;
    dayHours?.forEach((time) => {
      const startTime = moment(time.start, format);
      const endTime = moment(time.end, format);
      if (moment(currentTime, format).isBetween(startTime, endTime)) {
        isBetweenTime = true;
        return true;
      }
    });
    return isBetweenTime;
  }

  async convertTimeToUserTimezone(dayHours, timeZone) {
    const utcOffSet = moment.tz(timeZone).format('Z');
    const updateCurrentDayTime = [];
    const todayDate = moment().format('YYYY-MM-DD');
    const userTimeZone = moment.tz.guess();
    dayHours?.forEach((time) => {
      const startTime = moment(
        new Date(`${todayDate} ${time.start} UTC${utcOffSet}`)
      ).tz(userTimeZone);
      const endTime = moment(
        new Date(`${todayDate} ${time.end} UTC${utcOffSet}`)
      ).tz(userTimeZone);
      const startTimeNew = moment(
        moment.utc(
          `${todayDate} ${time?.start} UTC${utcOffSet}`,
          'YYYY-MM-DD HH:mm Z'
        )
      ).tz(userTimeZone);
      const endTimeNew = moment(
        moment.utc(
          `${todayDate} ${time?.end} UTC${utcOffSet}`,
          'YYYY-MM-DD HH:mm Z'
        )
      ).tz(userTimeZone);
      let newTime = {
        start: this.checkSameDate(startTime, todayDate)
          ? startTime.format('HH:mm')
          : '00:00',
        startTime: this.checkSameDate(startTimeNew, todayDate)
          ? startTimeNew.format('HH:mm')
          : '00:00',
        endTime: this.checkSameDate(endTimeNew, todayDate)
          ? endTimeNew.format('HH:mm')
          : '23:59',
        end: this.checkSameDate(endTime, todayDate)
          ? endTime.format('HH:mm')
          : '23:59'
      };
      updateCurrentDayTime.push(newTime);
    });
    return updateCurrentDayTime;
  }

  checkSameDate(momentTime, todayDate) {
    return todayDate === momentTime.format('YYYY-MM-DD');
  }

  checkIsSameTimeZone(clientZone) {
    const clientTimezone = moment.tz(clientZone).zoneAbbr();
    const userTimeZone = moment.tz(moment.tz.guess()).zoneAbbr();
    return clientZone === moment.tz.guess() && userTimeZone === clientTimezone;
  }
}
