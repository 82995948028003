import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { SwUpdate } from '@angular/service-worker';
import { interval, Subject, throwError } from 'rxjs';
import { catchError, takeUntil } from 'rxjs/operators';
import { Constants } from '../../constants/constants';
import { HttpWrapperService } from '../http-service/http-wrapper.service';

@Injectable({
  providedIn: 'root'
})
export class PwaService {
  installPromptEvent: any;
  pingHasBeenStarted = false;
  stopPingSubject$: Subject<boolean> = new Subject<boolean>();

  constructor(
    private router: Router,
    private swUpdate: SwUpdate,
    private httpClientWrapper: HttpWrapperService
  ) {
    window.addEventListener('beforeinstallprompt', (event) => {
      // Prevent Chrome <= 67 from automatically showing the prompt
      event.preventDefault();
      // Stash the event so it can be triggered later.
      this.installPromptEvent = event;
    });
    if (swUpdate.isEnabled) {
      // swUpdate.checkForUpdate()
      //    .then(() => console.log('checking for updates'));
      interval(6 * 60 * 60).subscribe(() =>
        swUpdate
          .checkForUpdate()
          .then(() => console.log('checking for updates'))
      );
    }
  }

  public checkForUpdates(): void {
    this.swUpdate.available.subscribe(() => this.promptUser());
  }

  private promptUser(): void {
    this.swUpdate.activateUpdate().then(() => document.location.reload());
  }

  public stopPing(): void {
    this.pingHasBeenStarted = false;
    this.stopPingSubject$.next(true);
  }

  public pingUser(): void {
    if (this.pingHasBeenStarted) {
      return;
    }
    interval(10000)
      .pipe(takeUntil(this.stopPingSubject$))
      .subscribe(() => {
        this.httpClientWrapper
          .post('sample', Constants.apiPaths.pingUser, {})
          .pipe(
            catchError((e) => {
              if (e && e.message && e.message.indexOf('401') >= 0) {
                this.router.navigateByUrl(`${Constants.routes.login}`);
              } else {
                console.error(e);
                return throwError(e);
              }
            })
          )
          .subscribe(() => {
            this.pingHasBeenStarted = true;
          });
      });
  }
}
