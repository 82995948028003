import { Roles } from '../../constants/schedule-config';
import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  UrlTree
} from '@angular/router';
import { Observable } from 'rxjs';
import { UtilsService } from 'src/app/core/services/utils/utils.service';

@Injectable({
  providedIn: 'root'
})
export class CobrowseRecordingGuard {
  constructor(private utils: UtilsService) {}
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    if (this.utils.userHasRole(Roles.recording)) {
      return true;
    } else {
      false;
    }
  }
}
