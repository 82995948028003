import { Injectable } from '@angular/core';
import { HttpWrapperService } from '../../../../../../../core/services/http-service/http-wrapper.service';
import { Constants } from '../../../../../../../core/constants/constants';
import { catchError, map } from 'rxjs/operators';
import { Observable, Subject, throwError } from 'rxjs';
import {
  CreateRoomReq,
  FeedbackReq,
  InviteRes,
  RoomData,
  RoomEndSubject,
  RoomInfoRes,
  SmallRoomChannelData,
  SmallRoomChannelDataRes
} from '../../../../../../../core/constants/video-config';
import {
  KbSearchRes,
  Participant,
  RoomCreatedData
} from '../../modals/call-config';
import { CommonRes } from '../../../../../../../core/constants/common.enum';
import { QueueCall } from '../../../../modals/queue.config';

@Injectable({
  providedIn: 'root'
})
export class CallService {
  constructor(private httpClientWrapper: HttpWrapperService) {}

  public endCall$ = new Subject<{ callEnded: boolean; roomName: string }>();
  public sendJoinCallSystemMessageUpdate$ = new Subject<any>();
  public openAudioSettings$ = new Subject<boolean>();
  public switchChatView = new Subject<boolean>();
  public switchRoom$ = new Subject<boolean>();
  public startVideoOnMobile$ = new Subject<string>();
  getAndSendData(data: RoomEndSubject) {
    this.endCall$.next(data);
  }
  getRoomStatus(roomName: string): string | any {
    return this.httpClientWrapper
      .post('sample', `${Constants.apiPaths.videoStatus}?roomName=${roomName}`)
      .pipe(
        map((res: any) => {
          return res;
        })
      )
      .pipe(
        catchError((e) => {
          return throwError(e);
        })
      );
  }

  getToken(identity): Observable<string | any> {
    return this.httpClientWrapper
      .post('sample', `${Constants.apiPaths.getToken}?identity=${identity}`, {})
      .pipe(
        map((res: any) => {
          return res.token;
        })
      )
      .pipe(
        catchError((e) => {
          return throwError(e);
        })
      );
  }

  createRoom(req): Observable<CreateRoomReq | any> {
    return this.httpClientWrapper
      .post('sample', Constants.apiPaths.createRoom, req)
      .pipe(
        map((res: any) => {
          return {
            roomSid: res.roomSid,
            record_participants_on_connect: res.record_participants_on_connect
          };
        })
      )
      .pipe(
        catchError((e) => {
          return throwError(e);
        })
      );
  }

  passRoomInfo(roomInfo) {
    return this.httpClientWrapper
      .post('sample', Constants.apiPaths.passRoomInfo, roomInfo)
      .pipe(
        map((res: any) => {
          return res;
        })
      )
      .pipe(
        catchError((e) => {
          return throwError(e);
        })
      );
  }

  passVideoRoomInfo(roomCreatedData: RoomCreatedData) {
    return this.httpClientWrapper
      .post('sample', Constants.apiPaths.passVideoRoomInfo, roomCreatedData)
      .pipe(
        map((res: any) => {
          return res;
        })
      )
      .pipe(
        catchError((e) => {
          return throwError(e);
        })
      );
  }

  getRoomParticipantList(roomName: string): Observable<Participant[] | any> {
    return this.httpClientWrapper
      .post('sample', Constants.apiPaths.getParticipantList, {
        roomName: roomName
      })
      .pipe(
        map((res: any) => {
          return res?.participants;
        })
      )
      .pipe(
        catchError((e) => {
          return throwError(e);
        })
      );
  }

  createChannel(): Observable<SmallRoomChannelData | any> {
    return this.httpClientWrapper
      .post('sample', Constants.apiPaths.createChannel, {})
      .pipe(map((obj: SmallRoomChannelDataRes) => obj?.channel))
      .pipe(catchError((e) => throwError(e)));
  }

  getInviteLink(inviteReq): Observable<InviteRes | any> {
    return this.httpClientWrapper
      .post('sample', Constants.apiPaths.getInviteLink, inviteReq)
      .pipe(
        map((res: InviteRes) => {
          return res.data;
        })
      )
      .pipe(
        catchError((e) => {
          return throwError(e);
        })
      );
  }

  getRoomInfo(code: string): Observable<RoomData> {
    return this.httpClientWrapper
      .get(`${Constants.apiPaths.getRoomInfo}/${code}`)
      .pipe(
        map((res: RoomInfoRes) => {
          return res.data;
        })
      )
      .pipe(
        catchError((e) => {
          return throwError(e);
        })
      );
  }

  roomClosed(roomName): string | any {
    return this.httpClientWrapper
      .post('sample', Constants.apiPaths.roomClosed, { channelSid: roomName })
      .pipe(
        map((res: any) => {
          return res;
        })
      )
      .pipe(
        catchError((e) => {
          return throwError(e);
        })
      );
  }

  submitFeedback(req: FeedbackReq) {
    return this.httpClientWrapper
      .post('', Constants.apiPaths.feedbackCreate, req)
      .pipe(
        map((res: CommonRes) => {
          return res;
        })
      )
      .pipe(
        catchError((e) => {
          return throwError(e);
        })
      );
  }

  initiateOutbound(
    invitecode: string,
    domain_userid: string,
    roomName: string
  ): string | any {
    return this.httpClientWrapper
      .post('sample', Constants.apiPaths.outboundInitiated, {
        invitecode: invitecode,
        snowplow_domain_userid: domain_userid,
        roomName
      })
      .pipe(
        map((res: any) => {
          return res;
        })
      )
      .pipe(
        catchError((e) => {
          return throwError(e);
        })
      );
  }

  endCall(roomName) {
    return this.httpClientWrapper
      .post('', `${Constants.apiPaths.videoEnd}?roomName=${roomName}`, {})
      .pipe(
        map((res: CommonRes) => {
          return res;
        })
      )
      .pipe(
        catchError((e) => {
          return throwError(e);
        })
      );
  }

  addActiveObserveGuestIdOrSnowPlowId(inProgressCalls: QueueCall[]) {
    return inProgressCalls?.map((call) => {
      if (call?.observeEnabledValues && call?.isAgentOnCall) {
        let latestCall = {
          ...call
        };
        const index = Object.values(call.observeEnabledValues)?.indexOf(true);
        const activeGuestIdOrSnowplowId = Object.keys(
          call.observeEnabledValues
        )[index];
        if (activeGuestIdOrSnowplowId?.includes('guest_')) {
          latestCall.observeActiveGuestId = activeGuestIdOrSnowplowId;
        } else {
          latestCall.snowPlowUserIdForFirstGuest = activeGuestIdOrSnowplowId;
        }
        return latestCall;
      }
      return call;
    });
  }

  getParticipantIdentityForIcon(identity: string): string {
    identity = identity.toLowerCase();
    if (identity.includes('guest')) {
      const name = identity.split('_');
      return `${name[0]} ${name[1]}`;
    } else {
      const fullName = identity.split(' ');
      const initials = fullName.map((name) => name.charAt(0)).join('');
      return initials.toUpperCase();
    }
  }

  findSmallAndMainRoomCount(participantList: Participant[]): {
    smallRoomCount: number;
    mainRoomCount: number;
  } {
    const smallRoomCount = participantList?.filter((part) => {
      return (
        part?.attributes.inSmallRoom &&
        !part?.attributes?.disconnected_timestamp
      );
    })?.length;

    const mainRoomCount = participantList?.filter((part) => {
      return (
        !part?.attributes.inSmallRoom &&
        !part?.attributes?.disconnected_timestamp
      );
    })?.length;
    return {
      smallRoomCount,
      mainRoomCount
    };
  }

  kbSearch(roomName: string, search_string: string) {
    return this.httpClientWrapper
      .post('', `${Constants.apiPaths.kbSearch}/${roomName}`, { search_string })
      .pipe(
        map((res: KbSearchRes) => {
          return res.request_id;
        })
      )
      .pipe(
        catchError((e) => {
          return throwError(e);
        })
      );
  }

  declineInvite(code: string): any {
    return this.httpClientWrapper
      .post('sample', Constants.apiPaths.declineInvite, { invite_code: code })
      .pipe(
        map((res: any) => {
          return res?.message;
        })
      )
      .pipe(
        catchError((e) => {
          return throwError(e);
        })
      );
  }
}
