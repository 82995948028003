import {
  getOutboundInfo,
  getUserDetailsSelector
} from '../../../state/app.selectors';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
  CurrentClient,
  TokenReq,
  UserInfo
} from '../../../core/constants/common.enum';
import { Constants } from '../../../core/constants/constants';
import { RoomData, RoomKeys } from '../../../core/constants/video-config';
import { UtilsService } from '../../../core/services/utils/utils.service';
import { UserDetails } from '../../../core/constants/dashboard-modal';
import { Subject } from 'rxjs';
import { select, Store } from '@ngrx/store';
import * as AppState from '../../../state/app.state';
import { catchError, take, takeUntil } from 'rxjs/operators';
import { OutBoundInfo } from '../../../core/constants/outbound-config';
import { SharedService } from '../../../core/services/shared-service/shared.service';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { CallKeys } from '../new-queue/modals/queue.config';
import { CallService } from '../new-queue/modules/call/services/call-service/call.service';
import { RootUtilsService } from '../../core/services/root-utils/root-utils.service';
import * as appActions from '../../../state/app.actions';
import { Participant } from '../new-queue/modules/call/modals/call-config';
import { PwaService } from '../../../core/services/pwa-service/pwa.service';
import { InviteLinkCheckService } from '../../core/services/invite-link-check/invite-link-check.service';
import { FcfsModalComponent } from '../fcfs-modal/fcfs-modal.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';

const LinkType = {
  g: 'guest_link',
  c: 'guest_cancel_link',
  e: 'guest_modify_link',
  a: 'agent_link'
};

@Component({
  selector: 'app-save-redirect',
  templateUrl: './save-redirect.component.html',
  styleUrls: ['./save-redirect.component.scss']
})
export class SaveRedirectComponent implements OnInit, OnDestroy {
  token: string;
  userDetails: UserDetails;
  domainId: number;
  isMobile = false;
  outBoundInfo: OutBoundInfo;
  roomName: string;
  chatMembers: Participant[];
  showGuestError: boolean;
  client: CurrentClient;

  isOutBound = false;
  isDecline: boolean;
  isFullScreen = false;

  destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(
    private appStore: Store<AppState.State>,
    private callService: CallService,
    private router: Router,
    private route: ActivatedRoute,
    private rootUtilsService: RootUtilsService,
    private sharedService: SharedService,
    private toaster: ToastrService,
    private translate: TranslateService,
    private utils: UtilsService,
    private sw: PwaService,
    private inviteLinkCheckService: InviteLinkCheckService,
    private modalService: NgbModal,
    private spinner: NgxSpinnerService
  ) {}

  ngOnDestroy(): void {
    this.destroy$.next(true);
  }

  ngOnInit(): void {
    this.isDecline = this.route.snapshot.queryParams?.decline;
    if (this.isDecline) {
      this.spinner.show();
      this.callDeclineInvite();
      return;
    }
    const inviteCode = this.route.snapshot.paramMap.get('inviteCode');
    const linkType = this.route.snapshot.paramMap.get('linkType');
    this.isFullScreen = this.route.snapshot.queryParams?.fullscreen === '1';
    if (linkType) {
      this.getCorrectLinkAndRedirect(linkType, inviteCode);
    } else {
      this.getStoreInformation();
    }
  }

  callDeclineInvite() {
    const code = this.route.snapshot.queryParams?.code;
    this.callService.declineInvite(code).subscribe(
      (message: string) => {
        this.spinner.hide();
        this.toaster.success(message);
      },
      (error: any) => {
        this.spinner.hide();
        this.toaster.error(error?.error?.message);
      }
    );
  }

  // handle this function for invite short link

  getCorrectLinkAndRedirect(linkType: string, inviteCode: string) {
    const link = LinkType[linkType];
    this.callService
      .getRoomInfo(inviteCode)
      .pipe(
        catchError(() => {
          if (linkType !== 'a') {
            this.showGuestError = true;
          } else {
            this.toaster.error(
              this.translate.instant('save.callNotInProgress')
            );
            this.router.navigateByUrl(Constants.routes.dashboard);
          }
          return [];
        }),
        take(1)
      )
      .subscribe((roomData: RoomData) => {
        this.storeLocalstorageValue(roomData);
        if (linkType === 'a') {
          this.logUserAndRedirect(roomData);
        } else {
          window.location.href = roomData[link];
        }
      });
  }

  logUserAndRedirect(roomData: RoomData) {
    const userDetails = this.utils.getUserInfo();
    if (roomData?.user_token || userDetails?.user_token) {
      const userInfo: UserInfo = {
        user_token: roomData?.user_token || userDetails?.user_token,
        user_id: roomData?.user_id || userDetails?.user_id,
        timestamp: roomData?.timestamp,
        tenantid: roomData?.tenantid || userDetails?.tenantid
      };
      this.utils.setUserInfo(userInfo);
      this.appStore.dispatch(
        new appActions.LoadUserDetailsAction(userInfo?.user_id)
      );
      this.appStore
        .pipe(select(getUserDetailsSelector), takeUntil(this.destroy$))
        .subscribe((response: UserDetails) => {
          this.userDetails = response;
          if (this.userDetails) {
            this.checkIsFirstComeFirstServe(roomData);
          }
        });
    } else {
      this.signOut();
    }
  }

  private getStoreInformation(): void {
    this.appStore
      .pipe(select(getUserDetailsSelector), takeUntil(this.destroy$))
      .subscribe((response: UserDetails) => {
        this.userDetails = response;
      });
    this.executeInviteData();
    this.appStore
      .pipe(select(getOutboundInfo), takeUntil(this.destroy$))
      .subscribe((response: OutBoundInfo) => {
        this.outBoundInfo = response;
      });
    this.outBoundInfo = this.utils.getVideoRoomStatus(
      false,
      RoomKeys.outboundInfo
    );
  }

  storeLocalstorageValue(roomInfo) {
    this.rootUtilsService.setCallSpecificInfoInLocal(
      roomInfo?.queue_position_id,
      [CallKeys.inviteCode, CallKeys.smallRoomName],
      [this.token, roomInfo?.small_room_chat]
    );
  }

  executeInviteData() {
    this.client = this.utils.getClient();
    this.domainId = this.route.snapshot.queryParams?.domainId;
    this.token = this.route.snapshot.queryParams?.code;
    this.utils.setVideoRoomStatus([RoomKeys.roomCode], [this.token]);
    this.callService
      .getRoomInfo(this.token)
      .pipe(takeUntil(this.destroy$))
      .pipe(
        catchError(() => {
          this.toaster.error(this.translate.instant('save.callNotInProgress'));
          this.router.navigateByUrl(Constants.routes.dashboard);
          return null;
        })
      )
      .subscribe((roomInfo: RoomData) => {
        this.storeLocalstorageValue(roomInfo);
        if (this.userDetails) {
          this.checkIsFirstComeFirstServe(roomInfo);
        } else {
          this.logUserAndRedirect(roomInfo);
        }
      });
  }

  checkIsFirstComeFirstServe(roomInfo) {
    if (
      roomInfo?.fcfs_used &&
      +roomInfo?.fcfs_agent_id !== this.userDetails.user_id
    ) {
      this.sharedService.setFcFsData({
        fcfs_used: roomInfo.fcfs_used,
        fcfs_agent_name: roomInfo?.fcfs_agent_name
      });
      const modalRef = this.modalService.open(FcfsModalComponent, {
        centered: true
      });
      modalRef.componentInstance.agentName = roomInfo?.fcfs_agent_name;
      this.inviteLinkCheckService.redirectToSubDomain(
        Constants.routes.dashboard,
        roomInfo
      );
    } else {
      this.inviteLinkCheckService.checkCallStatus(
        roomInfo,
        this.userDetails,
        this.token,
        this.outBoundInfo,
        false,
        true,
        this.isFullScreen
      );
      this.destroy$.next(true);
    }
  }

  signOut() {
    const deviceToken = this.utils.getToken();
    if (deviceToken) {
      const tokenReq: TokenReq = {
        token: deviceToken
      };
      this.utils.unAssignToken(tokenReq).subscribe(
        (response) => {
          console.log('notification enabled', response?.message);
          localStorage.removeItem(Constants.notification);
          localStorage.removeItem(Constants.deviceToken);
          this.sw.stopPing();
        },
        (error) => {
          console.log('error in notification', error);
        }
      );
    }
    localStorage.removeItem(Constants.userInfo);
    this.router.navigate([Constants.routes.login], {
      queryParams: { redirectURL: location.href.replace(location.origin, '') }
    });
  }
}
