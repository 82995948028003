import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-fcfs-modal',
  templateUrl: './fcfs-modal.component.html',
  styleUrls: ['./fcfs-modal.component.scss']
})
export class FcfsModalComponent {
  @Input() agentName: string;

  constructor(public activeModal: NgbActiveModal) {}
}
