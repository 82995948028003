import { Injectable } from '@angular/core';
import { Observable, Subject, throwError } from 'rxjs';
import { Constants } from '../../../../../../../core/constants/constants';
import { catchError, map } from 'rxjs/operators';
import { HttpWrapperService } from '../../../../../../../core/services/http-service/http-wrapper.service';
import {
  ChannelCreateReq,
  ChannelCreateRes,
  ChatMessage,
  ChatResponse,
  MediaUrlUpdate,
  Member,
  MemberUpdateRes,
  Message,
  MessageAttribute,
  MessageUpdateReq,
  NewChannelData,
  SendReq,
  TranslateSub,
  TranslateTextRes,
  TypingInfo,
  UpdateDeleteMemberRes
} from '../../../../../../../core/constants/chat-config';
import { RoomKeys } from '../../../../../../../core/constants/video-config';
import { UtilsService } from '../../../../../../../core/services/utils/utils.service';
import { Participant, ParticipantRes } from '../../modals/call-config';
import * as moment from 'moment/moment';
import * as appActions from '../../../../../../../state/app.actions';
import { CallKeys } from '../../../../modals/queue.config';
import { Store } from '@ngrx/store';
import * as AppState from '../../../../../../../state/app.state';

@Injectable({
  providedIn: 'root'
})
export class ChatService {
  updateTempMediaUrl$: Subject<MediaUrlUpdate> = new Subject<MediaUrlUpdate>();
  messageUpdatedSocket$: Subject<string> = new Subject<string>();
  translateOn$: Subject<TranslateSub> = new Subject<TranslateSub>();
  public passKbSearchResult$ = new Subject<{
    channelSid: string;
    msg: string;
  }>();

  constructor(
    private httpClientWrapper: HttpWrapperService,
    private utils: UtilsService,
    private appStore: Store<AppState.State>
  ) {}

  channelCreate(req: ChannelCreateReq): Observable<NewChannelData | any> {
    return this.httpClientWrapper
      .post('', Constants.apiPaths.channelCreate, req)
      .pipe(
        map((res: ChannelCreateRes) => {
          return res.data;
        })
      )
      .pipe(
        catchError((e) => {
          return throwError(e);
        })
      );
  }

  addMember(req): Member | any {
    return this.httpClientWrapper
      .post('sample', Constants.apiPaths.addMembers, req)
      .pipe(
        map((res: { member: Member }) => {
          return res?.member;
        })
      )
      .pipe(
        catchError((e) => {
          return throwError(e);
        })
      );
  }

  getMemberList(roomName: string): Observable<Participant[]> {
    return this.httpClientWrapper
      .post('', `${Constants.apiPaths.getMembers}?channelSid=${roomName}`, {})
      .pipe(
        map((res: ParticipantRes) => {
          return res.members;
        })
      )
      .pipe(
        catchError((e) => {
          return throwError(e);
        })
      );
  }

  createMessage(req: SendReq): Observable<Message> {
    return this.httpClientWrapper
      .post('sample', Constants.apiPaths.createMessage, req)
      .pipe(
        map((res: { message: Message }) => {
          return res.message;
        })
      )
      .pipe(
        catchError((e) => {
          return throwError(e);
        })
      );
  }

  updateMessage(req: MessageUpdateReq): ChatMessage | any {
    return this.httpClientWrapper
      .post('sample', Constants.apiPaths.updateMessage, req)
      .pipe(
        map((res: { message: ChatMessage }) => {
          return res?.message;
        })
      )
      .pipe(
        catchError((e) => {
          return throwError(e);
        })
      );
  }

  updateMember(req, identity): string | any {
    return this.httpClientWrapper
      .post(
        'sample',
        `${Constants.apiPaths.updateMember}?identity=${identity}`,
        req
      )
      .pipe(
        map((res: MemberUpdateRes) => {
          return res;
        })
      )
      .pipe(
        catchError((e) => {
          return throwError(e);
        })
      );
  }

  memberUpdated(roomName: string, members?: Participant[]): string | any {
    return this.httpClientWrapper
      .post('sample', Constants.apiPaths.memberUpdated, {
        channelSid: roomName,
        members
      })
      .pipe(
        map((res: any) => {
          return res;
        })
      )
      .pipe(
        catchError((e) => {
          return throwError(e);
        })
      );
  }

  updateAndDeleteMember(req, identity): any {
    return this.httpClientWrapper
      .post(
        'sample',
        `${Constants.apiPaths.updateAndDeleteMember}?identity=${identity}`,
        req
      )
      .pipe(
        map((res: UpdateDeleteMemberRes) => {
          return res;
        })
      )
      .pipe(
        catchError((e) => {
          return throwError(e);
        })
      );
  }

  addAndCheckAgentJoin(req): any {
    return this.httpClientWrapper
      .post('sample', Constants.apiPaths.addAndCheckAgentJoin, req)
      .pipe(
        map((res: string) => {
          return res;
        })
      )
      .pipe(
        catchError((e) => {
          return throwError(e);
        })
      );
  }

  retrieveMessagePagination(
    channelSid: string,
    pageToken: string = '',
    messageList?: Message[],
    currentRoomName?: string,
    isScrolled?: boolean
  ): Observable<ChatResponse> {
    const pageSize = 20;
    return this.httpClientWrapper
      .post('sample', Constants.apiPaths.retrieveMessagePagination, {
        channelSid,
        pageToken,
        pageSize
      })
      .pipe(
        map((obj: any) => {
          const chatRes: ChatResponse = {
            message: [],
            nextPageToken: obj?.nextPageUrl
              ? obj.nextPageUrl?.split('=')[4]?.includes('%')
                ? obj.nextPageUrl?.split('=')[4].split('%')?.[0]
                : obj.nextPageUrl?.split('=')[4]
              : ''
          };
          obj?.instances?.reverse()?.forEach((msg: any, index: number) => {
            const message = { ...msg };
            message.attributes = JSON.parse(msg.attributes);
            if (
              msg?.sid === messageList?.[index]?.sid &&
              msg?.conversationSid === messageList?.[index]?.conversationSid &&
              msg?.index === messageList?.[index]?.index
            ) {
              message.tempMediaUrl = messageList?.[index]?.tempMediaUrl;
            }
            chatRes.message.push(message);
          });
          if (!isScrolled) {
            this.appStore.dispatch(
              new appActions.UpdateInProgressCallsAction(
                [CallKeys.nextPageToken],
                [chatRes.nextPageToken],
                currentRoomName ? currentRoomName : channelSid
              )
            );
          }
          return chatRes;
        })
      )
      .pipe(catchError((e) => throwError(e)));
  }

  getUnconsumedMessageIndex(identity, channelSid): Observable<number | any> {
    return this.httpClientWrapper
      .post('', Constants.apiPaths.getUnconsumedMessageIndex, {
        channelSid,
        identity
      })
      .pipe(
        map((res: any) => {
          return res?.lastIndex;
        })
      )
      .pipe(
        catchError((e) => {
          return throwError(e);
        })
      );
  }

  sendTypingStatus(req): TypingInfo | any {
    return this.httpClientWrapper
      .post('sample', Constants.apiPaths.typing, req)
      .pipe(
        map((res: TypingInfo) => {
          return res;
        })
      )
      .pipe(
        catchError((e) => {
          return throwError(e);
        })
      );
  }

  updateLastConsumedMessageIndex(
    identity: string,
    channelSid: string,
    index: number
  ) {
    return this.httpClientWrapper
      .post('sample', Constants.apiPaths.updateLastConsumedMessageIndex, {
        identity,
        channelSid,
        index
      })
      .pipe(
        map((res: any) => {
          return res;
        })
      )
      .pipe(
        catchError((e) => {
          return throwError(e);
        })
      );
  }

  downloadFile(url: string) {
    return this.httpClientWrapper
      .getFileUsingUrl(url)
      .pipe(
        map((res: Blob) => {
          return res;
        })
      )
      .pipe(
        catchError((e) => {
          return throwError(e);
        })
      );
  }

  getToken(identity: string) {
    return this.httpClientWrapper
      .post('sample', Constants.apiPaths.getChatToken, { identity })
      .pipe(map((obj: any) => obj?.token))
      .pipe(catchError((e) => throwError(e)));
  }

  getTranslate(req) {
    return this.httpClientWrapper
      .post('sample', Constants.apiPaths.translateText, req)
      .pipe(
        map((res: TranslateTextRes) => {
          return res;
        })
      )
      .pipe(
        catchError((e) => {
          return throwError(e);
        })
      );
  }

  showMessage(attribute: MessageAttribute, fullNameOfAgent: string): boolean {
    if (attribute && attribute?.private) {
      return (
        attribute.visible_to.includes('agents') ||
        attribute.visible_to.includes(fullNameOfAgent)
      );
    } else {
      return true;
    }
  }

  getParticipantName(author: string, participants: Participant[]): string {
    const participantsList = participants
      ? participants
      : this.utils.getVideoRoomStatus(false, RoomKeys.participantList);
    const participant = participantsList?.find(
      (participant: Participant) => participant.identity === author
    );
    if (
      participant?.attributes &&
      (participant.attributes?.full_name || participant.attributes?.first_name)
    ) {
      return participant?.attributes?.full_name
        ? participant?.attributes?.full_name
        : participant?.attributes?.last_name
        ? `${
            participant?.attributes?.first_name
          } ${participant?.attributes?.last_name?.charAt(0)}`
        : participant?.attributes?.first_name;
    } else {
      return author.replace('_', ' ');
    }
  }

  getTimeForNotification(dateTime: string): string {
    const time = dateTime ? moment.utc(new Date(dateTime)).fromNow() : '';
    return time === 'in a few seconds' ? 'a few seconds ago' : time;
  }
}
