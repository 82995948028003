<div class="notification" [placement]="[ 'bottom-right']"
     [autoClose]="'outside'"
     [ngbPopover]="notification" #closeLink="ngbPopover"
     [popoverClass]="'alert-popover'">
  <span class="common-count unread-chat-count d-flex justify-content-center" *ngIf="notificationList?.length"></span>
</div>
<ng-template #notification>
  <div class="notification-popover">
    <div class="notify-head">
      <span [innerHTML]="'settings.notificationSetting.notifications' | translate"></span>
      <a class="pointer d-flex align-items-center" (click)="settings()">
        <svg-icon name="settings" class="setting-icon"></svg-icon>
      </a>
    </div>
    <ng-container *ngIf="notificationList?.length">
      <div class="not-list" *ngFor="let notification of notificationList; let i = index;">
        <div class="body" [ngClass]="{'pointer': notification?.attr?.data?.type === 'joined_queue'}" (click)="onBodyClick(notification?.attr?.data?.queue_position_id)">{{notification?.attr?.notification?.body}}</div>
        <span *ngIf="notification?.time" class="time-info">{{notification.time}}</span>
      </div>
    </ng-container>
    <ng-container *ngIf="!notificationList?.length">
      <div class="no-notification">No New Notifications</div>
    </ng-container>
  </div>
</ng-template>
