import {
  HTTP_INTERCEPTORS,
  HttpClient,
  HttpClientModule
} from '@angular/common/http';
import {
  APP_INITIALIZER,
  CUSTOM_ELEMENTS_SCHEMA,
  NgModule,
  SecurityContext
} from '@angular/core';
import { AngularFireModule } from '@angular/fire/compat';
import { AngularFireMessagingModule } from '@angular/fire/compat/messaging';
import { ReactiveFormsModule } from '@angular/forms';
import { BrowserModule, DomSanitizer } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ServiceWorkerModule } from '@angular/service-worker';
import { PickerModule } from '@ctrl/ngx-emoji-mart';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { EffectsModule } from '@ngrx/effects';
import {
  MetaReducer,
  StoreModule,
  USER_PROVIDED_META_REDUCERS
} from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import {
  TranslateDefaultParser,
  TranslateLoader,
  TranslateModule,
  TranslateParser
} from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { AngularSvgIconModule, SvgIconRegistryService } from 'angular-svg-icon';
import LogRocket from 'logrocket';
import createNgrxMiddleware from 'logrocket-ngrx';
import { NgxMasonryModule } from 'ngx-masonry';
import { NgxSpinnerModule } from 'ngx-spinner';
import { ToastrModule } from 'ngx-toastr';
import { AppRoutingModule } from 'src/app/app-routing.module';
import { AppComponent } from 'src/app/app.component';
import { A2hsComponent } from 'src/app/common/a2hs/a2hs.component';
import { PageNotFoundComponent } from 'src/app/common/page-not-found/page-not-found.component';
import { environment } from '../environments/environment';
import { Constants } from './core/constants/constants';
import { svgIcons } from './core/constants/icons';
import { CachingInterceptor } from './core/interceptors/cache/request-cache.interceptor';
import { PipeModule } from './core/pipes/pipe.module';
import { AppService } from './core/services/app/app.service';
import { RequestCache } from './core/services/cache/request-cache.service';
import { metaReducers } from './state/app.reducer';
import { State } from './state/app.state';
import { effects, reducers } from './store/models/store-index';
import { LinkyModule } from 'ngx-linky';

const logrocketMiddleware = createNgrxMiddleware(LogRocket, {
  actionSanitizer: function (action) {
    return {
      ...action
    };
  }
});

export function getMetaReducers(): MetaReducer<State>[] {
  return metaReducers.concat([logrocketMiddleware]);
}

class CustomTranslateParser extends TranslateDefaultParser {
  constructor() {
    super();
  }
  public getValue(target: any, key: string): any {
    // if (environment.local) {
    //   return `~${result}~`;
    // } else {
    //   return result;
    // }
    return super.getValue.apply(this, [target, key]);
  }
}

export function initializeApp(appService: AppService) {
  return (): Promise<any> => {
    return appService.init();
  };
}

@NgModule({
  declarations: [AppComponent, PageNotFoundComponent, A2hsComponent],
  imports: [
    AngularSvgIconModule.forRoot(),
    BrowserModule,
    AppRoutingModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production
    }),
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      },
      parser: { provide: TranslateParser, useClass: CustomTranslateParser }
    }),
    ReactiveFormsModule,
    NgxSpinnerModule,
    BrowserAnimationsModule,
    NgbModule,
    NgxMasonryModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireMessagingModule,
    PickerModule,
    StoreModule.forRoot(reducers),
    StoreDevtoolsModule.instrument({
      name: 'optimy-pwa',
      maxAge: 25,
      logOnly: environment.production
    }),
    EffectsModule.forRoot(effects),
    ToastrModule.forRoot(),
    PipeModule,
    LinkyModule
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: initializeApp,
      deps: [AppService],
      multi: true
    },
    RequestCache,
    { provide: HTTP_INTERCEPTORS, useClass: CachingInterceptor, multi: true },
    {
      provide: 'googleTagManagerId',
      useValue: location?.host.includes(Constants.clients.cellcom.toLowerCase())
        ? environment.cellcomGtmId
        : environment.royfossGtmId
    },
    {
      provide: USER_PROVIDED_META_REDUCERS,
      useFactory: getMetaReducers
    }
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule {
  constructor(
    private svgRegistry: SvgIconRegistryService,
    private domSanitizer: DomSanitizer
  ) {
    this.registerSvgIcons();
  }

  private registerSvgIcons(): void {
    svgIcons.forEach((icon) => {
      const trustedResourceUrl =
        this.domSanitizer.bypassSecurityTrustResourceUrl(
          `${location.origin}${icon.iconPath}`
        );
      const trustedUrl = this.domSanitizer.sanitize(
        SecurityContext.RESOURCE_URL,
        trustedResourceUrl
      ) as string;
      this.svgRegistry.loadSvg(trustedUrl, icon.iconName);
    });
  }
}

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}
