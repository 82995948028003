import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { filter } from 'rxjs/operators';
import {
  InAppNotification,
  InAppNotificationType
} from './in-app-notification.model';

@Injectable({
  providedIn: 'root'
})
export class InAppNotificationService {
  private defaultId = 'default-notification-id';

  private subject = new Subject<InAppNotification>();

  constructor() {}

  onAddNotification(): Observable<InAppNotification> {
    return this.subject.asObservable();
  }

  success(message: string, options?: any): InAppNotification {
    return this.notification(InAppNotificationType.Success, message, options);
  }

  error(message: string, options?: any): InAppNotification {
    return this.notification(InAppNotificationType.Error, message, options);
  }

  info(message: string, options?: any): InAppNotification {
    return this.notification(InAppNotificationType.Info, message, options);
  }

  warn(message: string, options?: any): InAppNotification {
    return this.notification(InAppNotificationType.Warning, message, options);
  }

  notification(
    notificationType: InAppNotificationType,
    message: string,
    options?: any
  ): InAppNotification {
    const notification = new InAppNotification({
      ...options,
      id: options?.id || this.defaultId,
      type: notificationType,
      message,
      onButtonAction: new Subject<InAppNotification>(),
      onBodyAction: new Subject<InAppNotification>(),
      onCancelButtonAction: new Subject<InAppNotification>()
    });
    this.subject.next(notification);
    return notification;
  }

  // clear notification
  clear(id = this.defaultId) {
    this.subject.next(new InAppNotification({ id }));
  }
}
