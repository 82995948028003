import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DeviceTypeCheckService {
  constructor() {}

  isOnMobile(): boolean {
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      navigator.userAgent
    );
  }
}
