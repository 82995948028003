import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { UserInfo } from 'src/app/core/constants/common.enum';
import { Constants } from 'src/app/core/constants/constants';
import { environment } from 'src/environments/environment';
import { retryBackoff } from 'backoff-rxjs';

declare global {
  interface Window {
    apiInfo: any;
  }
}

@Injectable({
  providedIn: 'root'
})
export class HttpWrapperService {
  public unauthorizedCall = new Subject<boolean>();

  userInfo: UserInfo;

  constructor(private httpClient: HttpClient) {}

  public get<T>(apiRoute: string, params?: HttpParams): Observable<T> {
    const startTime = new Date();

    const httpCall = this.httpClient.get<T>(environment.baseApiUrl + apiRoute, {
      params,
      headers: this.buildHeaders(''),
      observe: 'response'
    });

    return this.retryHttp(httpCall, 'GET', null, params, startTime);
  }

  public post<I, T>(
    userInContext: string,
    apiRoute: string,
    body?: I
  ): Observable<T> {
    const startTime = new Date();

    const httpCall = this.httpClient.post<T>(
      environment.baseApiUrl + apiRoute,
      body,
      {
        headers: this.buildHeaders(userInContext),
        observe: 'response'
      }
    );
    return this.retryHttp(httpCall, 'POST', body, null, startTime);
  }

  // Used for the backend directly call.
  public postDirectlyToRoute<I, T>(
    apiRoute: string,
    body?: I,
    extraHeaders?: any
  ): Observable<T> {
    const startTime = new Date();
    let headers = this.buildHeaders('');
    if (extraHeaders) {
      headers = { ...headers, ...extraHeaders };
    }
    const httpCall = this.httpClient.post<T>(apiRoute, body, {
      headers,
      observe: 'response'
    });

    return this.retryHttp(httpCall, 'POST', body, null, startTime);
  }

  public postReqForLambda(
    apiRoute: string,
    body: any,
    reqOpts?: any
  ): Observable<any> {
    return this.httpClient.post(apiRoute, body, reqOpts);
  }

  public put<I, T>(
    userInContext: string,
    apiRoute: string,
    body: I
  ): Observable<T> {
    const startTime = new Date();
    const httpCall = this.httpClient.put<T>(
      environment.baseApiUrl + apiRoute,
      body,
      {
        headers: this.buildHeaders(userInContext),
        observe: 'response'
      }
    );

    return this.retryHttp(httpCall, 'PUT', body, null, startTime);
  }

  public delete<I, T>(userInContext: string, apiRoute: string): Observable<T> {
    const startTime = new Date();
    const httpCall = this.httpClient.delete<T>(
      environment.baseApiUrl + apiRoute,
      {
        headers: this.buildHeaders(userInContext),
        observe: 'response'
      }
    );
    return this.retryHttp(httpCall, 'DELETE', null, null, startTime);
  }

  private retryHttp(
    response: Observable<any>,
    method: string,
    body?: any,
    params?: any,
    startTime?: any
  ): Observable<any> {
    window.apiInfo = window?.apiInfo ? window.apiInfo : [];
    return response
      .pipe(
        retryBackoff({
          initialInterval: 100,
          maxInterval: 20 * 1000,
          maxRetries: 1,
          resetOnSuccess: true,
          shouldRetry: (error) => {
            return error.status !== 401 && error.status !== 400;
          }
        })
      )
      .pipe(
        map((value) => {
          window.apiInfo.push({
            ...value,
            method: method,
            requestPayload: body,
            params: params,
            headers: null,
            time: startTime,
            duration:
              (new Date().getTime() - startTime?.getTime()) / 1000 + ' seconds'
          });
          if ((value as any)?.error) {
            throw new Error((value as any).error);
          }
          return value?.body;
        }),
        catchError((error) => {
          window.apiInfo.push(error);
          return error.status === 401 ? this.logout(error) : throwError(error);
        })
      );
  }

  private buildHeaders(userInContext: string): any {
    this.userInfo = JSON.parse(localStorage.getItem(Constants.userInfo));
    const header = {
      'x-username': userInContext || '',
      'x-request-id': Math.random().toString(36).replace('0.', '')
    };
    if (this.userInfo?.user_token) {
      return {
        ...header,
        usertoken: this.userInfo?.user_token
      };
    } else {
      return {
        ...header
      };
    }
  }
  private logout(error) {
    if (error.status === 401) {
      this.unauthorizedCall.next(true);
    }
    return throwError(error);
  }

  getFileUsingUrl(url: string) {
    return this.httpClient.get(url, { responseType: 'blob' });
  }
}
