import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import {
  ActivatedRoute,
  NavigationEnd,
  NavigationStart,
  Router
} from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { GoogleTagManagerService } from 'angular-google-tag-manager';
import * as moment from 'moment';
import { fromEvent, Observable, Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import {
  BookingConfig,
  CurrentClient,
  UserInfo
} from 'src/app/core/constants/common.enum';
import { PwaService } from 'src/app/core/services/pwa-service/pwa.service';
import { UtilsService } from 'src/app/core/services/utils/utils.service';
import { Clients, Constants } from './core/constants/constants';
import { AppService } from './core/services/app/app.service';
import { AngularFireMessaging } from '@angular/fire/compat/messaging';
import { environment } from '../environments/environment';
import { LoggingService } from './core/services/logging/logging.service';
import { DeviceTypeCheckService } from './modules/core/services/device-type-check/device-type-check.service';
import { ToastrService } from 'ngx-toastr';

declare global {
  interface Window {
    logs: any;
  }
}

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
  title = 'OPTIMY';
  userInfo: UserInfo;
  currentClient: CurrentClient;
  isBookingConfig: boolean;
  isMobile: boolean;

  offlineEvent: Observable<Event>;
  onlineEvent: Observable<Event>;
  subscription: Subscription;
  destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(
    private activatedRoute: ActivatedRoute,
    private angularFireMessaging: AngularFireMessaging,
    public sw: PwaService,
    private translate: TranslateService,
    private utils: UtilsService,
    private gtmService: GoogleTagManagerService,
    private appService: AppService,
    private router: Router,
    private loggingService: LoggingService,
    private deviceTypeCheckService: DeviceTypeCheckService,
    private toast: ToastrService
  ) {
    this.sw.checkForUpdates();
    this.activatedRoute.queryParams.subscribe((params) => {
      const language = params['lang'] ? params['lang'] : 'en';
      if (Object.keys(params)?.length) {
        this.findTokenFromUrl(params);
      }
      if (language) {
        const userInfo = this.utils.getUserInfo();
        const navLang = navigator.language;
        moment.locale(navLang);
        if (userInfo?.default_language) {
          this.translate.setDefaultLang(userInfo.default_language);
          this.utils.setLanguage(userInfo.default_language);
        } else {
          this.translate.setDefaultLang(language);
          this.utils.setLanguage(language);
        }
      }
    });
    // this.initializeOpenTabsCount();
    this.initializeOnlineAndOfflineHandles();
    this.subscription = router.events.subscribe((event) => {
      if (event instanceof NavigationStart && !router.navigated) {
        const buildNumber = environment.appVersion?.split('.')[2];
        console.log('version-', buildNumber);
        const lastUpdated = this.utils.getLastUpdateTime();
        const diff = moment(new Date()).diff(moment(lastUpdated), 'hours');
        if (diff >= 1 || isNaN(diff)) {
          this.appService.getBuildNumber().subscribe((newBuildNumber) => {
            if (newBuildNumber && buildNumber !== newBuildNumber) {
              console.log('system reloaded');
              // @ts-ignore
              location.reload(true);
              this.utils.setLastUpdateTime(new Date().toString());
            }
          });
        }
      }
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
  }

  findTokenFromUrl(params: any) {
    if (Object.keys(params).includes('redirectURL')) {
      const urlEncoded = location.href;
      const decodedURL = decodeURIComponent(urlEncoded.split('=')[1]);
      const urlParams = new URLSearchParams(decodedURL.split('?')[1]);
      const token = urlParams.get('token');
      if (token) {
        this.utils.setDeviceToken(token);
        this.toast.success('token received from url');
      }
    } else if (params?.token) {
      this.toast.success('token received from url');
      this.utils.setDeviceToken(params?.token);
    }
  }

  initializeOnlineAndOfflineHandles() {
    this.onlineEvent = fromEvent(window, 'online');
    this.offlineEvent = fromEvent(window, 'offline');

    this.onlineEvent.pipe(takeUntil(this.destroy$)).subscribe(() => {
      // TODO: validate if it is really necessary to have actions here...
      // this.store.dispatch(AppActions.agentReconnectedAction());
      window.location.reload();
    });
  }

  ngOnInit() {
    this.isMobile = this.deviceTypeCheckService.isOnMobile();
    this.appHeight();
    this.getFirebaseToken();
    this.isBookingConfig =
      location?.href?.includes(BookingConfig.bookingFormConfig) ||
      location?.href?.includes(BookingConfig.roomName);
    this.userInfo = this.utils.getUserInfo();
    this.checkTokenExpiry();
    this.appService.findOutClient();
    this.currentClient = this.utils.getClient();
    this.router.events.subscribe((item) => {
      if (item instanceof NavigationEnd) {
        const gtmTag = {
          event: 'page',
          pageName: item.url
        };
        if (this.currentClient?.tenantName === Clients.cellcom) {
          this.gtmService.pushTag(gtmTag);
        }
      }
    });
    if (this.userInfo?.user_token) {
      this.sw.pingUser();
      this.loggingService.initializeLogging$.next(true);
    }
    this.overrideConsole();
  }

  overrideConsole() {
    window.logs = [];
    (function () {
      let oldLog = console.log;
      let oldWarn = console.warn;
      let oldInfo = console.info;
      let oldError = console.error;
      let oldClear = console.clear;

      console.log = function (message) {
        window.logs.push(['log:', arguments]);
        oldLog?.apply(console, arguments);
      };

      console.warn = function (message) {
        window.logs.push(['warn:', arguments]);
        oldWarn?.apply(console, arguments);
      };

      console.info = function (message) {
        window.logs.push(['info:', arguments]);
        oldInfo?.apply(console, arguments);
      };

      console.error = function (message) {
        window.logs.push(['error:', arguments]);
        oldError?.apply(console, arguments);
      };

      console.clear = function () {
        window.logs = [];
        oldClear();
      };
    })();
  }

  getFirebaseToken() {
    if ('Notification' in window) {
      this.angularFireMessaging.requestToken.subscribe(
        (token) => {
          if (!environment.production) {
            console.log(token);
          }
          if (token) {
            this.utils.setDeviceToken(token);
          }
        },
        (err) => {
          console.log('Unable to get permission to notify.', err);
        }
      );
      this.utils.receiveMessage();
    }
  }

  checkTokenExpiry() {
    const currentDate = moment(new Date()).unix();
    const resetTime = moment
      .unix(this.userInfo?.timestamp)
      .subtract(4, 'days')
      .unix();
    if (this.userInfo && currentDate > this.userInfo?.timestamp) {
      localStorage.removeItem(Constants.userInfo);
    } else if (
      this.userInfo &&
      currentDate > resetTime &&
      currentDate < this.userInfo?.timestamp
    ) {
      this.utils.refreshToken(this.userInfo).subscribe(
        (res) => {
          res['max_weekly_hours'] = this.userInfo?.max_weekly_hours;
          this.utils.setUserInfo(res);
        },
        (error) => {
          console.log('error in updating new token', error);
        }
      );
    }
  }

  @HostListener('window:resize')
  appHeight() {
    const doc: any = document.documentElement;
    doc.style.setProperty('--app-height', `${window.innerHeight}px`);
    if (this.isMobile) {
      const isIos = /iPhone|iPad|iPod/i.test(navigator.userAgent);
      doc.style.setProperty(
        'overscroll-behavior-y',
        isIos ? 'none' : 'contain'
      );
      if (!isIos) {
        document.body.style.setProperty('overscroll-behavior-y', 'none');
      }
    }
  }
}
