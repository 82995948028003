import { Pipe, PipeTransform } from '@angular/core';
import {
  MemberAttributes,
  Participant
} from '../../modules/root/new-queue/modules/call/modals/call-config';

@Pipe({
  name: 'getParticipantInfo'
})
export class GetParticipantInfoPipe implements PipeTransform {
  transform(member: Participant): string {
    let info = '';
    if (member.attributes?.first_name || member.attributes?.user_name) {
      info += this.getFirstAndOrLastName(member.attributes);
    } else if (member.attributes?.full_name) {
      info += member.attributes?.full_name.split(' ')[0];
    } else {
      info += member.identity.includes('guest')
        ? `Guest ${member.identity.split('_')[1]}`
        : member.identity;
    }
    return info;
  }

  getFirstAndOrLastName(attributes: MemberAttributes): string {
    let info = '';
    if (attributes.user_name) {
      info = attributes.user_name;
    } else {
      if (attributes?.first_name) {
        info = attributes.first_name;
      }
      if (attributes.last_name) {
        info += ' ';
        info += attributes?.last_name;
      }
    }
    return info;
  }
}
