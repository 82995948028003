import * as appActions from './app.actions';
import * as AppState from './app.state';
import { ActionReducer, MetaReducer } from '@ngrx/store';

const initialState: AppState.State = {
  userInfo: null,
  userDetails: null,
  isConnected: true,
  error: null,
  queueCallsAndLists: null,
  agentList: [],
  agentStatusUpdate: null,
  pendingCalls: [],
  inProgressCalls: [],
  scheduledCalls: [],
  activeCallQueueId: null,
  dashboardFilterInfo: null,
  reportInfo: [],
  languageList: [],
  outBoundInfo: null
};

export const metaReducer = (
  reducer: ActionReducer<AppState.State>
): ActionReducer<AppState.State> => {
  return (state, action) => {
    return reducer(state, action);
  };
};

export const metaReducers: MetaReducer<AppState.State>[] = [metaReducer];

export function appReducer(
  state = initialState,
  action: appActions.Action
): AppState.State {
  switch (action.type) {
    case appActions.LOAD_USER_DETAILS_SUCCESS: {
      return {
        ...state,
        userDetails: action.userDetails,
        error: null
      };
    }
    case appActions.LOAD_USER_DETAILS_FAILURE: {
      return {
        ...state,
        userDetails: null,
        error: action.error
      };
    }
    case appActions.UPDATE_USER_INFO: {
      return {
        ...state,
        userInfo: action.userInfo,
        error: null
      };
    }

    // Queue List
    case appActions.LOAD_QUEUE_CALLS_LIST_SUCCESS: {
      return {
        ...state,
        queueCallsAndLists: action.queueCallsAndLists,
        error: ''
      };
    }
    case appActions.LOAD_QUEUE_CALLS_LIST_FAILURE: {
      return {
        ...state,
        queueCallsAndLists: null,
        error: action.error
      };
    }
    case appActions.LOAD_PENDING_CALLS: {
      return {
        ...state,
        pendingCalls: action.pendingCalls
      };
    }
    case appActions.UPDATE_PENDING_CALLS: {
      const updatedPendingCallList = [...state.pendingCalls];
      const i = updatedPendingCallList.findIndex(
        (call) => call.room_name === action.roomName
      );
      action.key.forEach((key, index) => {
        updatedPendingCallList[i] = {
          ...updatedPendingCallList[i],
          [key]: action.value[index]
        };
      });
      updatedPendingCallList[i] = {
        ...updatedPendingCallList[i]
      };
      return {
        ...state,
        pendingCalls: [...updatedPendingCallList]
      };
    }
    case appActions.ADD_PENDING_CALLS_LIST: {
      let pendingCallList = [...state.pendingCalls];
      const callOn = pendingCallList?.findIndex(
        (progressCall) => progressCall?.room_name === action?.call?.room_name
      );
      if (callOn !== -1) {
        pendingCallList[callOn] = {
          ...pendingCallList[callOn],
          ...action?.call
        };
      } else {
        pendingCallList = [...pendingCallList, action.call];
      }

      const latestPendingCalls = [];
      pendingCallList?.forEach((call) => {
        latestPendingCalls.push({ ...call });
      });

      return {
        ...state,
        pendingCalls: [...latestPendingCalls]
      };
    }
    case appActions.DELETE_PENDING_CALL: {
      let newList = [...state.pendingCalls];
      const indexOfTheItem = newList.findIndex(
        (call) => call.room_name === action.roomName
      );
      if (indexOfTheItem !== -1) {
        newList?.splice(indexOfTheItem, 1);
      }
      return {
        ...state,
        pendingCalls: [...newList]
      };
    }
    case appActions.LOAD_IN_PROGRESS_CALLS: {
      return {
        ...state,
        inProgressCalls: action.inProgressCalls
      };
    }
    case appActions.UPDATE_IN_PROGRESS_CALLS: {
      const updatedProgressCallList = [...state.inProgressCalls];
      const i = updatedProgressCallList.findIndex(
        (call) => call.room_name === action.roomName
      );
      action.key.forEach((key, index) => {
        updatedProgressCallList[i] = {
          ...updatedProgressCallList[i],
          [key]: action.value[index]
        };
      });
      updatedProgressCallList[i] = {
        ...updatedProgressCallList[i]
      };
      return {
        ...state,
        inProgressCalls: [...updatedProgressCallList]
      };
    }
    case appActions.UPDATE_CONNECTED_PARTICIPANTS_IN_PROGRESS_CALLS: {
      const i = state.inProgressCalls.findIndex(
        (call) => call.room_name === action.roomName
      );
      const updatedProgressCallList = [...state.inProgressCalls];

      action.key.forEach((key) => {
        const guestIndex = updatedProgressCallList[
          i
        ]?.connected_participants?.findIndex(
          (guest) => guest?.user_id === action.guest_user_id
        );

        let connectedGuest = [];
        if (updatedProgressCallList[i]?.connected_participants?.length) {
          connectedGuest = [
            ...updatedProgressCallList[i]?.connected_participants
          ];
        }

        if (guestIndex >= 0) {
          connectedGuest[guestIndex] = {
            ...connectedGuest[guestIndex],
            [key]: action.value[key],
            user_id: action.guest_user_id
          };
        } else {
          connectedGuest.push({
            [key]: action.value[key],
            user_id: action.guest_user_id,
            user_type: 'guest'
          });
        }
        updatedProgressCallList[i] = {
          ...updatedProgressCallList[i],
          connected_participants: [...connectedGuest]
        };
      });
      updatedProgressCallList[i] = {
        ...updatedProgressCallList[i]
      };
      return {
        ...state,
        inProgressCalls: [...updatedProgressCallList]
      };
    }
    case appActions.ADD_IN_PROGRESS_CALLS_LIST: {
      let progressCallList = [...state.inProgressCalls];
      const callOn = progressCallList?.findIndex(
        (progressCall) => progressCall?.room_name === action?.call?.room_name
      );
      if (callOn !== -1) {
        progressCallList[callOn] = {
          ...progressCallList[callOn],
          ...action?.call
        };
      } else {
        progressCallList = [...progressCallList, action.call];
      }
      const latestInProgressCalls = [];
      progressCallList?.forEach((call) => {
        latestInProgressCalls.push({ ...call });
      });
      return {
        ...state,
        inProgressCalls: [...latestInProgressCalls]
      };
    }
    case appActions.DELETE_IN_PROGRESS_CALL: {
      let newList = [...state.inProgressCalls];
      const indexOfTheItem = newList.findIndex(
        (call) => call.room_name === action.roomName
      );
      if (indexOfTheItem !== -1) {
        newList?.splice(indexOfTheItem, 1);
      }
      const latestInProgressCalls = [];
      newList?.forEach((call) => {
        latestInProgressCalls.push({ ...call });
      });

      return {
        ...state,
        inProgressCalls: [...latestInProgressCalls]
      };
    }
    case appActions.LOAD_SCHEDULED_CALLS: {
      return {
        ...state,
        scheduledCalls: action.scheduledCalls
      };
    }
    case appActions.UPDATE_SCHEDULED_CALLS: {
      const updatedScheduledCallList = [...state.scheduledCalls];
      const i = updatedScheduledCallList.findIndex(
        (call) => call.room_name === action.roomName
      );
      action.key.forEach((key, index) => {
        updatedScheduledCallList[i] = {
          ...updatedScheduledCallList[i],
          [key]: action.value[index]
        };
      });
      updatedScheduledCallList[i] = {
        ...updatedScheduledCallList[i]
      };
      return {
        ...state,
        scheduledCalls: [...updatedScheduledCallList]
      };
    }
    case appActions.SET_ACTIVE_CALL_ID: {
      return {
        ...state,
        activeCallQueueId: action.queueId
      };
    }

    // Agent List
    case appActions.LOAD_AGENT_LIST_SUCCESS: {
      return {
        ...state,
        agentList: action.agentList,
        error: ''
      };
    }
    case appActions.LOAD_AGENT_LIST_FAILURE: {
      return {
        ...state,
        agentList: [],
        error: action.error
      };
    }

    case appActions.AGENT_STATUS_UPDATE: {
      return {
        ...state,
        agentStatusUpdate: action.agentStatusUpdate
      };
    }

    case appActions.LOAD_DASHBOARD_REPORT_SUCCESS: {
      return {
        ...state,
        reportInfo: action.dashboardReport,
        error: ''
      };
    }
    case appActions.LOAD_DASHBOARD_REPORT_FAILURE: {
      return {
        ...state,
        reportInfo: [],
        error: action.error
      };
    }

    case appActions.LOAD_LANGUAGE_LIST_SUCCESS: {
      return {
        ...state,
        languageList: action.languageList,
        error: ''
      };
    }
    case appActions.LOAD_LANGUAGE_LIST_FAILURE: {
      return {
        ...state,
        languageList: [],
        error: action.error
      };
    }

    case appActions.SET_DASHBOARD_FILTER_INFO: {
      return {
        ...state,
        dashboardFilterInfo: {
          allData: action.allData,
          xAxisLabel: action.xAxisLabel
        }
      };
    }

    case appActions.UPDATE_OUTBOUND_INFO: {
      return {
        ...state,
        outBoundInfo: action.payload
      };
    }

    default:
      return state;
  }
}
