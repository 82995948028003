import { Injectable } from '@angular/core';
import { Observable, Subject, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { Constants } from '../../constants/constants';
import {
  MainOutbountData,
  OutboundData
} from '../../constants/outbound-config';
import {
  Agent,
  AgentListRes,
  AgentUpdateRes,
  CallHistory,
  CallHistoryRes,
  ReportsList,
  ReportsRes,
  RoomKeys,
  StatusUpdateReq
} from '../../constants/video-config';
import { HttpWrapperService } from '../http-service/http-wrapper.service';
import { UtilsService } from '../utils/utils.service';
import demoOutboundData from '../../../../assets/api-mocks/demo-outbound-data.json';
import * as moment from 'moment/moment';
import { environment } from '../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class VideoChatService {
  public closeCobrowse = new Subject<boolean>();

  private mockOutboundData = this.modifyOutboundMockData(
    demoOutboundData?.data
  );

  constructor(
    private httpClientWrapper: HttpWrapperService,
    private utils: UtilsService
  ) {}

  getAgentList(): Observable<Agent[] | any> {
    return this.httpClientWrapper
      .post('sample', Constants.apiPaths.getAgentList, {})
      .pipe(
        map((res: AgentListRes) => {
          return res.data;
        })
      )
      .pipe(
        catchError((e) => {
          return throwError(e);
        })
      );
  }

  agentStatusUpdate(
    statusUpdateReq: StatusUpdateReq
  ): Observable<AgentUpdateRes> {
    return this.httpClientWrapper
      .post('sample', Constants.apiPaths.agentStatusUpdate, statusUpdateReq)
      .pipe(
        map((res: AgentUpdateRes) => {
          return res;
        })
      )
      .pipe(
        catchError((e) => {
          return throwError(e);
        })
      );
  }

  passAssignAgentInfo(agentInfo) {
    return this.httpClientWrapper
      .post('sample', Constants.apiPaths.passAssignAgent, agentInfo)
      .pipe(
        map((res: any) => {
          return res;
        })
      )
      .pipe(
        catchError((e) => {
          return throwError(e);
        })
      );
  }

  getCallHistory(req): Observable<CallHistory[]> {
    return this.httpClientWrapper
      .post('sample', Constants.apiPaths.getCallHistory, req)
      .pipe(
        map((res: CallHistoryRes) => {
          return res.data;
        })
      )
      .pipe(
        catchError((e) => {
          return throwError(e);
        })
      );
  }

  getReportHistory(req): Observable<ReportsList[] | any> {
    return this.httpClientWrapper
      .get(`${Constants.apiPaths.readReports}`, req)
      .pipe(
        map((res: ReportsRes) => {
          return res?.data?.main_dataset;
        })
      )
      .pipe(
        catchError((e) => {
          return throwError(e);
        })
      );
  }

  getOutboundList(): Observable<OutboundData[] | any> {
    return this.httpClientWrapper
      .post('sample', `${Constants.apiPaths.getOutboundInfo}`, {
        seconds_last_seen: 120
      })
      .pipe(
        map((res: MainOutbountData | any) => {
          const tenantId =
            this.utils.getClient()?.tenant_id ||
            this.utils.getUserInfo()?.tenantid;
          if (tenantId === 8) {
            let data;
            if (res?.data?.data?.length) {
              const latestListForOutbound = !environment.production
                ? this.modifyOutboundListWithColors(res.data.data)
                : res.data.data;
              data = [...latestListForOutbound, ...this.mockOutboundData];
            } else {
              data = [...this.mockOutboundData];
            }
            return {
              ...res?.data,
              data: [...data]
            };
          } else {
            return res.data;
          }
        })
      )
      .pipe(
        catchError((e) => {
          return throwError(e);
        })
      );
  }

  cobrowseUpdate(
    roomName: string,
    cobrowseAction: string,
    clientIdentity: string
  ): string | any {
    return this.httpClientWrapper
      .post('sample', Constants.apiPaths.cobrowseSocket, {
        roomName,
        cobrowseAction,
        clientIdentity
      })
      .pipe(
        map((res: any) => {
          return res;
        })
      )
      .pipe(
        catchError((e) => {
          return throwError(e);
        })
      );
  }

  triggerSocket(
    action: string,
    roomName: string,
    isObserve: any,
    value: boolean,
    clientIdentity: string
  ) {
    if (roomName && !isObserve) {
      this.cobrowseUpdate(roomName, action, clientIdentity).subscribe((res) => {
        this.utils.setVideoRoomStatus([RoomKeys.isCobrowse], [value]);
        console.log('socket triggered successfully', res);
      });
    }
  }

  modifyOutboundListWithColors(list) {
    let modifiedList = [];
    list?.forEach((res) => {
      modifiedList.push({ ...res, color: ['orange'] });
    });
    return modifiedList;
  }

  modifyOutboundMockData(mockData) {
    return mockData?.map((res) => {
      const rndInt = Math.floor(Math.random() * 4) + 1;
      res.session_start_timestamp = moment(new Date())
        .subtract(rndInt, 'minutes')
        .unix();
      if (!environment.production) {
        res.color = ['green'];
      }
      return res;
    });
  }
}
